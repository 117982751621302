import {FC, useCallback} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {PlatformListItem} from '../../../../../interfaces/platform';
import {ONE_FIELDS} from '../../../../../shared/constants/path';
import {logoBigSize, orgLogoSmall} from '../../../../../shared/utils/get-images-size';
import {getPlatformWorkingTime} from '../../../../../shared/utils/get-platform-working-time';
import {OverflowTooltip} from '../../../../overflow-tooltip/overflow-tooltip';
import IconCall from '../../../../svg-icon-components/icon-call';
import IconLocation from '../../../../svg-icon-components/icon-location';
import IconTime from '../../../../svg-icon-components/icon-time';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {ServiceName} from '../../components/service-name/service-name';
import * as SC from './organization-platform-card.style';

interface OrganizationPlatformsCardProps {
	platform: PlatformListItem
}

export const OrganizationPlatformCard: FC<OrganizationPlatformsCardProps> = ({platform}) => {

	const navigate = useNavigate();
	const [workingTime, workingDescription] = getPlatformWorkingTime(platform.day_info);
	const goToPlatformCard = useCallback((options?: NavigateOptions) => {
		navigate(ONE_FIELDS.replace(':id', platform.id), options);
	}, [platform.id]);

	const goToPlatformCardMap = useCallback(() => {
		goToPlatformCard({state: {map: true}, replace: true});
	}, [goToPlatformCard]);

	return (
		<CardContainer>
			<Logo orgLogo={orgLogoSmall(platform.org_logo)} orgName={platform.org_name} serviceName={platform.org_name}
				  serviceLogo={logoBigSize(platform.logo)} platformPage onClick={goToPlatformCard}/>
			<SC.NameWrapper>
				<SC.LinkMap onClick={goToPlatformCardMap}>на карту</SC.LinkMap>
				<ServiceName serviceName={platform.org_name}/>
			</SC.NameWrapper>
			<SC.TextContentWrapper>
				<div >
					<SC.TextWithIcon>
						<IconLocation backgroundColor="#352C8D"/>
						<OverflowTooltip
							classNameContainer={SC.TooltipContainer}
							content={displayAddress(platform.city, platform.location_info.address)}
						/>
					</SC.TextWithIcon>
				</div>
				<SC.TextWithIcon>
					<IconTime backgroundColor="#352C8D"/>
					<SC.Label>{workingTime}</SC.Label>
					<SC.Label>{workingDescription}</SC.Label>
				</SC.TextWithIcon>
				<SC.TextWithIcon>
					<IconCall backgroundColor="#352C8D"/>
					<SC.Label>{platform.contacts.viber.at(0) ?? '-'}</SC.Label>
				</SC.TextWithIcon>
				<SC.Description>{platform.description}</SC.Description>
			</SC.TextContentWrapper>
		</CardContainer>
	);
};
