export const monthNames: { [key: string]: number } = {
	'января': 0, 'февраля': 1, 'марта': 2, 'апреля': 3, 'мая': 4, 'июня': 5,
	'июля': 6, 'августа': 7, 'сентября': 8, 'октября': 9, 'ноября': 10, 'декабря': 11
};

export function formatDateFromFields(dateStr: string) {
	const monthNames = [
		'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
		'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
	];

	const [_, month, day] = dateStr.split('-');
	return `${parseInt(day)} ${monthNames[parseInt(month) - 1]}`;
}  // 2024-10-23 => 23 октября

export function formatDateToStingFromLabelDate(dateString: string) {
	const [day, monthLabel] = dateString.split(' ');
	const month = monthNames[monthLabel] + 1;

	// Получаем текущий год
	const year = new Date().getFullYear();

	// Форматируем дату в YYYY-MM-DD
	return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
} // 23 октября => 2024-10-23


export function formatDateToDayMouth(dateString: string): { day: string, month: string, year: string } {
	const [year, month, day] = dateString.split('-');

	return {day, month, year};
}

// 2024-10-23 => {day: 23, month: 10, year: 2024}

export function formatDateDefaultType(dateString: string): Date {
	const now = new Date();
	const year = now.getFullYear();
	const parts = dateString.split(' ');
	const day = parseInt(parts[0]);
	const month = monthNames[parts[1] as keyof typeof monthNames];

	if (isNaN(day) || month === undefined) {
		throw new Error('Invalid date format');
	}
	return new Date(year, month, day);
}// 22 октября => Tue Oct 22 2024 09:26:43 GMT+0300 (Moscow Standard Time)

export function formatDate(date: Date): string {
	const options: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'long'};

	return new Intl.DateTimeFormat('ru-RU', options).format(date);
} // Tue Oct 22 2024 09:26:43 GMT+0300 (Moscow Standard Time) => 22 октября

export function formatDateString(dateString: Date) {
	const date = new Date(dateString);

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}// Tue Oct 22 2024 09:26:43 GMT+0300 (Moscow Standard Time) => 2024-10-22

export const formatDateAndWeekday = (date: Date): string => {
	const weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']; // Array of weekdays
	const day = String(date.getDate()).padStart(2, '0'); // Get the day, adding a leading 0 if necessary
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1

	const weekday = weekdays[date.getDay()]; // Get the weekday from the array

	return `${weekday}, ${day}.${month}`; // Format the string
}; // Tue Oct 22 2024 09:26:43 GMT+0300 (Moscow Standard Time) => Пн 22.10
export const convertToISOFormat = (date: Date): string => {
	// Получаем компоненты даты
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');

	// Формируем строку в формате ISO
	return `${year}-${month}-${day}T${hours}:${minutes}`;
};// => 2024-11-19T09:30:00

export const formatDatePayment = (dateString: string) => {
	const date = new Date(dateString);
	const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
	return date.toLocaleDateString('ru-RU', options);
};
// 2025-02-06T12:45:41.039443Z =>  06.02.2025
