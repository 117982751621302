import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ServiceType = styled.p`
	color: var(--text-Accent);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Description = styled.p`
	color: var(--text-Accent, #1B1B1B);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const OrderDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S);
	align-self: stretch;
`;
export const AbonementDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S);
	position: relative;
	width: 100%;
`;
export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
`;
export const Detail = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Price = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const VisitingConditionsWrapper = styled.div`
	display: flex;
	padding: 8px 16px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	border-radius: var(--Corner-M, 8px);
	background: var(--background-Alternative);
`;
export const TitleWrapperConditions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--Spacing-desctop-Block-S);
`;
export const TitleConditions = styled.h4`
	color: var(--text-B, #352C8D);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Conditions = styled.p`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const PaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-M);
	align-self: stretch;
`;
export const Modal = css`
	max-width: 690px !important;
	gap: var(--Spacing-desctop-Block-M, 24px) !important;
	@media screen and (max-width: 490px) {
		padding: 10px !important;
	}
`;
export const IconBonusesWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 30%;
	height: fit-content;
	transform: translateY(-30%);
	@media screen and (max-width: 550px) {
		top: 80%;
	}
	@media screen and (max-width: 450px) {
		top: 82%;
		span {
			font-size: 14px;
			top: 34%;
			left: 30%;
		}
	}
`;
export const IconBonuses = styled.img`
	@media screen and (max-width: 450px){
		width: 124px;
		height: 124px;
	}
`;
export const BunusesWrapper = styled.div`
	display: flex;
	padding: var(--Spacing-desctop-Body-M, 8px) var(--Spacing-desctop-Block-S, 16px);
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: var(--Spacing-desctop-Body-M, 8px);
	border-radius: var(--Corner-S, 4px);
	border: 1px solid var(--border-Neutral, #CFCFCF);
	position: absolute;
	right: 0;
	bottom: 8px;

	@media screen and (max-width: 550px){
		position: relative;
		align-items: flex-start;
		border: none;
		padding: 0;
	}
`;
export const HaveBonuses = styled.p`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const ResultPrice = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Body-M, 8px);
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
