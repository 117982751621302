import {createSlice} from '@reduxjs/toolkit';

import {fetchGetConfig} from '../thunks/payment/get-config';
import {fetchGetPayment} from '../thunks/payment/get-payment';

interface InitialStatePayment {
	payment:{
		abonement_id: null;
		created_at: string;
		order_id: null;
		paid_by_balance: number;
		price: number;
		requester: string;
		status: string;
		token: null;
		uid: string;
		updated_at: null;
	},
	config: {
		discounts: {
			duration_in_month: number;
			passive_cashback: number;
			populate_balance_high_amount: number;
			populate_balance_high_cashback: number;
			populate_balance_medium_amount: number;
			populate_balance_medium_cashback: number;
			populate_balance_small_amount: number;
			populate_balance_small_cashback: number;
			register_cashback: number;
		},
		referral_state: {
			referral_profit: number;
			referrer_profit: number;
		},
		rewards: {
			order_reward: number;
			payment_reward: number;
		}
	}
}

const initialState: InitialStatePayment = {
	payment:{
		abonement_id: null,
		created_at: '1970-01-01T00:00:00Z',
		order_id: null,
		paid_by_balance: 0,
		price: 0,
		requester: '',
		status: '',
		token: null,
		uid: '',
		updated_at: null
	},
	config: {
		discounts: {
			duration_in_month: 0,
			passive_cashback: 0,
			populate_balance_high_amount: 0,
			populate_balance_high_cashback: 0,
			populate_balance_medium_amount: 0,
			populate_balance_medium_cashback: 0,
			populate_balance_small_amount: 0,
			populate_balance_small_cashback: 0,
			register_cashback: 0
		},
		referral_state: {
			referral_profit: 0,
			referrer_profit: 0
		},
		rewards: {
			order_reward: 0,
			payment_reward: 0
		}
	}
};

export const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetConfig.fulfilled, (state, action) => {
			state.config = action.payload?.data;
		});
		builder.addCase(fetchGetPayment.fulfilled, (state, action) => {
			state.payment = action.payload?.data;
		});
	}
});
