import styled from '@emotion/styled';

export const Window = styled.div`
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999999;
	background: var(--overlay-normal, rgba(153, 153, 153, 0.30));
`;
export const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: var(--Spacing-desctop-Block-L, 32px) 30px var(--Spacing-desctop-Block-L, 32px) var(--Spacing-desctop-H2, 40px);
	overflow: auto;
	max-height: calc(100vh - 4px);

	&::-webkit-scrollbar-thumb {
		background-color: var(--green);
		border-radius: 20px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: #edf6f3;
		border-radius: 20px;
		width: 4px;
		margin: 10px 0;
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	@media screen and (max-width: 470px) {
		gap: 16px;
		padding: var(--Spacing-desctop-Body-M) var(--Spacing-desctop-Block-S);
	}
`;

export const Container = styled.div`
	background-color: var(--white-color);
	border-radius: 8px;
	outline: 1px solid var(--text-G);
	max-width: 610px;
	position: absolute;
	width: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding-right: 10px;
`;
export const CloseButton = styled.button`
	position: absolute;
	right: 30px;
	top: 32px;

	@media screen and (max-width: 470px) {
		right: 16px;
		top: 18px;
	}
`;
export const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
	filter: brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
`;
