import styled from '@emotion/styled';

export const FormRadio = styled.div`
	margin-bottom: 10px;

	input[type=radio] {
		display: none;
	}

	input[type="radio"]:checked,
	input[type="radio"]:not(:checked) {
		position: absolute;
		left: -9999px;
	}

	input[type="radio"]:checked + label,
	input[type="radio"]:not(:checked) + label {
		display: inline-block;
		position: relative;
		padding-left: 28px;
		line-height: 20px;
		cursor: pointer;
	}

	input[type="radio"]:checked + label:before,
	input[type="radio"]:not(:checked) + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 1px solid #999999;
		background-color: transparent;
	}

	input[type="radio"]:checked + label:before,
	input[type="radio"]:not(:checked) + label:before {
		border-radius: 100%;
	}

	input[type="radio"]:checked + label:after,
	input[type="radio"]:not(:checked) + label:after {
		content: "";
		position: absolute;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}

	input[type="radio"]:checked + label:after,
	input[type="radio"]:not(:checked) + label:after {
		left: 5px;
		top: 5px;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background-color: #4D4D4D;
	}

	input[type="radio"]:not(:checked) + label:after {
		opacity: 0;
	}

	input[type="radio"]:checked + label:after {
		opacity: 1;
	}
`;
export const Label = styled.label`
	color: #4D4D4D;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
