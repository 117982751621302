import styled from '@emotion/styled';

interface ContainerStyle {
	showButtonsControl?: boolean;
}

export const Container = styled.div<ContainerStyle>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    justify-content: space-between;
    padding: ${props => props.showButtonsControl ? '48px 20px 20px' : '20px'};
    background-color: var(--background-Alternative);
    color: var(--text-Strong);
	position: relative;
`;
export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: space-between;
	position: relative;
`;
export const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;
`;

export const Hr = styled.hr`
	width: 100%;
	background-color: #cfcfcf;
`;
export const Label = styled.span`
	font-weight: 300;
	font-size: 14px;
	line-height: 1.3;
	text-wrap: nowrap;
`;
export const DataContainer = styled.div`
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, minmax(174px, 1fr));
	gap: 20px;
`;
export const HasAbonement = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
`;
export const ListServices = styled.ul`
	font-weight: 400;
	font-size: 18px;
	line-height: 1.2;

	li {
		position: relative;
		padding-left: 20px;
	}

	li::before {
		font-size: 44px;
		content: '•';
		color: green;
		position: absolute;
		left: 0;
		top: 60%;
		transform: translateY(-50%);
	}
`;
export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;
`;

export const TextHoverWrapper = styled.div`
	position: relative;
	cursor: pointer;
`;

export const Price = styled.span`
    font-family: Jost, sans-serif;
    color: var(--text-Strong);
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    text-align: center;
`;
