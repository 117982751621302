import React, {memo} from 'react';

import * as SC from './for-payment.style';

export const ForPayment = memo(() => {
	return (
		<SC.ForPayment>Для оплаты, вы будете перенаправлены на защищенную платежную страницу процессинговой
			системы BePayd</SC.ForPayment>
	);
});
