import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	gap: 6px;
	position: absolute;
	top: 4px;
	right: 4px;
`;

