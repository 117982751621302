import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const OrderInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const RecommendationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 50%;
`;
export const TextContentWrapper= styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export const RecommendationTitle = styled.h3`
	color: var(--text-Accent);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Recommendation = styled.p`
	color: var(--text-Weak);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Button = css`
	padding: 8px;
	width: 100%;
`;
export const Bonuses = styled.p`
	color: var(--text-Weak);
	border-radius: 4px;
	padding: 8px;
	background: var(--background-Green-weak);
	font-size: 16px;
	font-style: normal;
	text-align: center;
	font-weight: 400;
	line-height: 20px;
`;
export const FooterModalWrapper =styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export const ButtonWrapper = styled.div`
	display: flex;
	gap: 8px;
`;
