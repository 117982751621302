import styled from '@emotion/styled';

export const ButtonPaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;
export const Description = styled.p`
	color: var(--text-Accent, #1B1B1B);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const AbonementInfoWrapper = styled(ButtonPaymentWrapper)`
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S, 16px);
`;
export const Price = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const LimitWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
`;
export const Limit = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const BonusesWrapper = styled(LimitWrapper)`
	gap: 8px;
	position: relative;
`;
export const IconBonusesWrapper = styled.div`
	position: absolute;
	right: 0;
	top: -30px;

	@media screen and (max-width: 470px) {
		top: -15px;
		span {
			font-size: 14px;
			top: 34%;
			left: 30%;
		}

		img {
			width: 124px;
			height: 124px;
		}
	}
`;
export const HasBonuses = styled.div`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	span{
		color: var(--text-Accent, #1B1B1B);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
`;
export const ResultPrice = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Body-M, 8px);
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	align-items: center;
`;
export const PriceRes = styled.span`
	color: var(--text-G, #1C8666);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const WorkTo = styled(Limit)`
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
`;
