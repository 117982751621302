import {Link} from 'react-router-dom';

import desktopLogo from '../../../../../assets/icon/logo-sporder.svg';
import {MAIN} from '../../../../../shared/constants/path';
import * as SC from './logo.style';

export const Logo = () => {
	return (
		<Link to={MAIN} id={'logo-header'}>
			<SC.LogoImage src={desktopLogo} alt="Логотип Sporder" title="Логотип Sporder" />
		</Link>
	);
};
