import styled from '@emotion/styled';

export const Text = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 2px 8px;
	color: var(--text-Strong);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
`;
