import React, {FC, memo, ReactNode} from 'react';

import * as SC from './order-detail.style';

interface Props {
	detailLabel: string;
	children: ReactNode;
}

export const OrderDetail:FC<Props> = memo(({detailLabel, children}) => {
	return (
		<SC.OrderDetailWrapper>
			<SC.TypeDetail>{detailLabel}</SC.TypeDetail>
			{children}
		</SC.OrderDetailWrapper>
	);
});

