import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: #EFEFEF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    color: var(--text-Strong);
    position: relative;
`;
export const HeaderCard = styled.div`
	display: flex;
	align-items: center;
	gap: 14px;
	margin-top: 10px;
	max-width: 86%;
	@media screen and (max-width: 600px) {
		max-width: 100%;
		margin-top: 30px;
	}
`;
export const OrgDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
`;
export const Logo = styled.img`
	width: 58px;
	height: 58px;
	border-radius: 50%;
`;
export const ServiceType = styled.div`
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
	margin-right: 30px;
	width: 92%;

	p {
		text-wrap: nowrap;
	}
`;
export const ServiceTypeMobile = styled.h4`
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
`;
export const Address = styled.div`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	margin-right: 30px;
	width: 82%;

	p {
		text-wrap: nowrap;
	}
`;
export const AddressMobile = styled.p`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;
export const Option = styled.span`
	color: #505050;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	text-wrap: nowrap;
`;
export const OptionData = styled.p`
	display: flex;
	gap: 6px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;
export const OptionsContainer = styled.div`
	display: flex;
	gap: 30px;
	justify-content: space-between;
	width: 100%;
`;
export const Options = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 50%;
	justify-content: space-between;
`;
export const OptionsData = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 6px;
	width: 100%;
`;
export const Price = styled.span`
	color: var(--green);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 138.5%;
`;

export const Details = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: var(--border-B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
`;

export const ButtonWrapper = styled.div`
	gap: 16px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`;
