import styled from '@emotion/styled';

export const Title =styled.h2`
	color: var(--text-Strong);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	text-align: center;
`;
