import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';

import vector_icon from '../../../../../assets/icon/icon_down_btn.svg';
import {AbonementType} from '../../../../../interfaces/abonement';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {servicesPlatformCheckedSelector} from '../../../../../redux/selectors/platforms-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchGetServicesByFilter} from '../../../../../redux/thunks/services/get-services-by-filter';
import {convertPriceToString} from '../../../../../shared/utils/converter-price';
import {logoBigSize, orgLogoSmall} from '../../../../../shared/utils/get-images-size';
import {isPlatformAbonement} from '../../../../../shared/utils/is-platform-abonement';
import {getAbonementTime} from '../../../../../shared/utils/time/converter-time';
import {getMonthsLabel, getWeeksLabel} from '../../../../../shared/utils/transform-text-label';
import {ButtonNew} from '../../../../buttons-new';
import {ModalNew} from '../../../../modal-new/modal-new';
import IconTime from '../../../../svg-icon-components/icon-time';
import {IconLock} from '../../../../svg-icon-components/Lock';
import {DetailWrapper} from '../detail-wrapper/detail-wrapper';
import {Name} from '../name/name';
import {OrgInfo} from '../org-info/org-info';
import {ServiceCard} from '../service-card/service-card';
import {SliderAbonements} from '../slider-abonements/slider-abonements';
import * as SC from './modal-details-abonement.style';

interface ModalDetailsAbonement {
	abonement: AbonementType
	closeWindow: () => void
	setOpenPymentWindow: () => void;
	setShowDetailsAbonement: Dispatch<SetStateAction<AbonementType | null>>;
}

export const ModalDetails: FC<ModalDetailsAbonement> = ({
															abonement, closeWindow, setOpenPymentWindow,
															setShowDetailsAbonement
														}) => {
	const BG_ICON = 'var(--yellow)';
	const services = useAppSelector(servicesPlatformCheckedSelector);
	const [isExpanded, setIsExpanded] = useState(false);
	const [shouldShowButton, setShouldShowButton] = useState(false);
	const textRef = useRef(null);
	const dispatch = useAppDispatch();

	const toggleExpand = () => setIsExpanded(prevState => !prevState);
	useEffect(() => {
		// Проверяем, превышает ли текст 3 строки
		if (textRef.current) {
			const {scrollHeight, clientHeight} = textRef.current;
			setShouldShowButton(scrollHeight > clientHeight);
		}
	}, [abonement.info.description]);

	useEffect(() => {
		dispatch(fetchGetServicesByFilter({abonement_id: [abonement.id], allow_view_only: true}));
	}, [abonement.id]);

	const timeLabel = getAbonementTime(abonement);

	return (
		<ModalNew onClickClose={closeWindow} classNameContainer={SC.Window}>
			<SC.ContentWrapper>
				<Name title={abonement.info.name}/>
				<SC.Hr/>
				<OrgInfo logo={orgLogoSmall(abonement.org_info.logo)} orgName={abonement.org_info.org_name}
						 address={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info
							 ? `${abonement.platform_info.city}, ${abonement.platform_info.location_info.address}` : 'Клубная карта'}/>
				<SC.DataContainer>
					<DetailWrapper label={'Количество занятий'} icon={<IconLock backgroundColor={BG_ICON}/>}
								   text={abonement.info.limit !== null ? abonement.info.limit.toString() : 'Безлимитно'}
								   unLimit={abonement.info.limit === null}/>
					<DetailWrapper label={'Длительность абонемента'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={'Month' in abonement.info.duration
									   ? `${abonement.info.duration.Month} ${getMonthsLabel(abonement.info.duration.Month)}`
									   : `${abonement.info.duration.Week} ${getWeeksLabel(abonement.info.duration.Week)}`}/>
					<SC.TextHoverWrapper>
						{timeLabel &&
							<DetailWrapper label={'Время для посещения'} icon={<IconTime backgroundColor={BG_ICON}/>}
										   text={timeLabel}/>}
					</SC.TextHoverWrapper>
				</SC.DataContainer>
				{services.length > 0 && <><SC.HasAbonement>Доступно в абонементе:</SC.HasAbonement>
					<SC.ServiceCardWrapper>
						{services.map(service => {
							return <ServiceCard key={service.id} title={service.service_type}
												logo={logoBigSize(service.logo)}
												id={service.id}/>;
						})}
					</SC.ServiceCardWrapper></>}
				<SC.DescriptionWrapper>
					<SC.Description isExpanded={isExpanded} ref={textRef}>{abonement.info.description}</SC.Description>
					{shouldShowButton && (
						<SC.ShowDescription
							onClick={toggleExpand}>
							<span>{isExpanded ? 'Скрыть' : 'Показать полностью'}</span>
							<SC.ShowDescriptionIcon isExpanded={isExpanded} src={vector_icon} alt="vector_icon"/>
						</SC.ShowDescription>
					)}
				</SC.DescriptionWrapper>
				{abonement.payment_method === 'CASH'
					? <SC.Price>Цена: {convertPriceToString(abonement.info.price)}</SC.Price>
					: <ButtonNew label={`Купить за ${convertPriceToString(abonement.info.price)}`} bgColor={'green'}
								 onClick={setOpenPymentWindow}/>}
				<div>
					<SC.HasAbonement>Еще абонементы:</SC.HasAbonement>
					<SliderAbonements
						platformId={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info ? abonement.platform_info.platform_id : undefined}
						org_name={!isPlatformAbonement(abonement.info.issuer) ? abonement.org_info.org_name : undefined}
						setShowDetailsAbonement={setShowDetailsAbonement}/>
				</div>
			</SC.ContentWrapper>
		</ModalNew>
	);
};
