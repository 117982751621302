import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {OrderType} from '../../../interfaces/enums';
import {TOrderClaimCreate} from '../../../interfaces/order';
import {dataNotSavedText, emptyMessage} from '../../../shared/constants/message-user';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';

export interface FetchSetOrderClaimUserResponse {
	status: number;
	data: {
		id: string;
	};
}

export const fetchSetOrderClaimUser = createAsyncThunk<
	FetchSetOrderClaimUserResponse,
	{ params: TOrderClaimCreate; allow: OrderType }
>(
	'fieldSlice/fetchSetOrderClaimUser',
	async (arg, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.setClaimOrderUser(arg.params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				return {status: response.status, data: response.data} as FetchSetOrderClaimUserResponse;
			}
			dispatch(setMessageUser({
				messageUser: emptyMessage,
				titleMessage: dataNotSavedText,
				textMessage: 'Заказ не оформлен. Что-то пошло не так'
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			return {status: response.status, data: response.data} as FetchSetOrderClaimUserResponse;

		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
			return rejectWithValue('Ошибка при выполнении запроса');
		}
	}
);
