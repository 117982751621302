import styled from '@emotion/styled';

export const BonusesIconText = styled.span`
	position: absolute;
	top: 30%;
	left: 32%;
	transform: translate(-20%, -20%);
	rotate: 4deg;
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
