import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ResultOrder = styled.p`
	color: var(--text-Weak, #4D4D4D);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--Spacing-desctop-Block-S, 16px);
	align-self: stretch;
`;
export const ThankYouMessage = styled.h2`
	color: var(--text-Weak, #4D4D4D);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const OfferWrapper = styled.div`
	display: flex;
	width: 430px;
	padding: var(--Spacing-desctop-Block-M, 24px);
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 16px;
	border-radius: var(--Corner-M, 8px);
	border: 1px solid var(--border-Neutral, #CFCFCF);
	margin: 0 auto;
`;
export const OfferTitle = styled.h2`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Presents = styled.p`
	color: var(--text-Weak, #4D4D4D);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Button = css`
	width: 200px !important;
`;
