import {FC, memo} from 'react';

import * as SC from './button-with-line-bottom.style';

interface Props {
	label: string;
	className?: string;
	onClick?: () => void;
	defaultColor?: 'yellow' | 'green' | 'purple';
}

export const ButtonWithLineBottom: FC<Props> = memo(({onClick, label, className, defaultColor = 'purple'}) => {
	return (
		<SC.Container className={className} onClick={onClick} defaultColor={defaultColor}>{label}</SC.Container>
	);
});
