import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {setIsAuthUser} from '../../../redux/reducers/user-data-reducer';
import {useResizeWindow} from '../../../shared/hooks/use-resize-window';
import {isAuth} from '../../../shared/utils/save-local-storage';
import {activateScroll, disabledScroll} from '../../../shared/utils/scroll-body';
import {BurgerMenu} from './components/burger-menu/burger-menu';
import {BurgerMenuButton} from './components/burger-menu-button/burger-menu-button';
import {CityInfo} from './components/city-info/city-info';
import {Logo} from './components/logo/logo';
import {NavBar} from './components/navbar/navbar';
import {UserInfo} from './components/user-info/user-info';
import * as SC from './header.style';

export const Header = () => {
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const width = useResizeWindow();

	useEffect(() => {
		if (isBurgerMenuOpen) {
			document.body.style.padding = ' 0 calc(20px - (100vw - 100%)) 0 0';
			disabledScroll();
		} else {
			document.body.style.padding = '0';
			activateScroll();
		}
	}, [isBurgerMenuOpen]);

	useEffect(() => {
		if (isAuth()) {
			dispatch(setIsAuthUser());
		}
	}, [dispatch]);

	const handleToggleBurgerMenu = useCallback(() => {
		setIsBurgerMenuOpen((isBurgerMenuOpen) => !isBurgerMenuOpen);
	}, []);

	return (
		<SC.Header>
			<SC.HeaderContent>
				{width < 1130 && <>
					<SC.HeaderContentAdaptive >
						<Logo/>
						<SC.HeaderControls>
							<CityInfo/>
							<UserInfo/>
							<BurgerMenuButton onClick={handleToggleBurgerMenu}/>
						</SC.HeaderControls>
					</SC.HeaderContentAdaptive>
					<NavBar/>
				</>}
				{width >= 1130 && <>
					<Logo/>
					<NavBar/>
					<SC.HeaderControls>
						<CityInfo/>
						<UserInfo/>
						<BurgerMenuButton onClick={handleToggleBurgerMenu}/>
					</SC.HeaderControls>
				</>}
			</SC.HeaderContent>
			<BurgerMenu isOpen={isBurgerMenuOpen} setIsOpen={setIsBurgerMenuOpen}/>
		</SC.Header>
	);
};
