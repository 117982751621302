import {AbonementDuration} from '../../../interfaces/abonement';
import {convertPriceToString} from '../../../shared/utils/converter-price';
import {getCountLabel, getMonthsLabel, getWeeksLabel} from '../../../shared/utils/transform-text-label';
import {getBonusesWordIcon} from './modal-anonim-payment/modal-anonim-payment';

interface BonusesAvailable {
	price: number;
	balance: number;
	useAllBonuses: boolean;
}

export const bonusesAvailable = ({price, balance, useAllBonuses}: BonusesAvailable) => {

	if(balance <= price && useAllBonuses){
		return '0 бонусов';
	}

	return useAllBonuses
		? getBonusesWordIcon((balance - price) / 100)
		: getBonusesWordIcon(balance / 100);
};
interface CalculateFinishPrice{
	price: number;
	balance: number;
	useAllBonuses: boolean;
}

export const calculateFinishPrice = ({price, balance, useAllBonuses}: CalculateFinishPrice): string => {
	if (price <= balance && useAllBonuses) {
		return '0 BYN';
	} else if (useAllBonuses) {
		return convertPriceToString(price - balance);
	}
		return convertPriceToString(price);

};

export const addToDate = (dateString: string, duration: AbonementDuration) => {
	const date = new Date(dateString);

	if ('Month' in duration) {
		date.setMonth(date.getMonth() + duration.Month);
	} else {
		const weeksToDays = duration.Week * 7;
		date.setDate(date.getDate() + weeksToDays);
	}

	const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
	return date.toLocaleDateString('ru-RU', options);
};

export const workingAbonementTo = (duration: AbonementDuration) => {
	const today = new Date();

	if ('Month' in duration) {
		today.setMonth(today.getMonth() + duration.Month);
	} else {
		const weeksToDays = duration.Week * 7;
		today.setDate(today.getDate() + weeksToDays);
	}

	const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
	return today.toLocaleDateString('ru-RU', options);
};

export const getLimitAbonement = (duration: AbonementDuration) => {

	return `${'Month' in duration
			? `${duration.Month} ${getMonthsLabel(duration.Month)}`
			: `${duration.Week} ${getWeeksLabel(duration.Week)}`}`;
};
export const getLimitClassesAbonement = (limit: number | null) => {

	return limit !== null ? `${limit} ${getCountLabel(limit)}` :  'Безлимит';
};
