import {createAsyncThunk} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {SetPayment} from '../../../api/query-params';
import {SearchPayment} from '../../../api/search-payment';
import {ORDER_SUCCESS, PAYMENT_SUCCESS_PAGE} from '../../../shared/constants/path';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchSetPayment = createAsyncThunk(
	'paymentSlice/fetchSetPayment',
	async (data: SetPayment, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPayment.setPayment(data);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				if ('Paid' in response.data.status) {
					if ('Abonement' in data.issuer) {
						window.location.href = `${PAYMENT_SUCCESS_PAGE.replace(':abonement_id', data.issuer.Abonement.id)}?payment_id=${response.data.status.Paid}`;
					}
					if ('Order' in data.issuer) {
						window.location.href = ORDER_SUCCESS.replace(':order_id', data.issuer.Order.id);
					}
					return {status: response.status, data: response.data};
				}
				window.open(response.data.status.BePaid.redirect_url, '_blank');
				return {status: response.status};
			}
			return {status: response.status, data: response.data.checkout};
		} catch (error) {
			if (isAxiosError(error)) {
				return handleAxiosError({dispatch, rejectWithValue, error});
			}
		}
	},
);
