import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {OrderType} from '../../../interfaces/enums';
import {TOrderCreate} from '../../../interfaces/order';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchSetOrderUser = createAsyncThunk(
	'fieldSlice/fetchSetOrderUser',
	async (arg: { params: TOrderCreate, allow: OrderType }, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.setBookOrderUser(arg.params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				return {status: response.status, data: response.data.id};
			}
			return rejectWithValue(response.data);
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
