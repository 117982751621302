import classNames from 'classnames';
import {FC} from 'react';

import remove from '../../../../assets/icon/delete_deps.svg';
import noImagePlaceholder from '../../../../assets/img/no_image.png';
import {ListWithIcons} from '../../../../components/list-with-icons/list-with-icons';
import IconCount from '../../../../components/svg-icon-components/icon-count';
import IconDollar from '../../../../components/svg-icon-components/icon-dollar';
import IconLocation from '../../../../components/svg-icon-components/icon-location';
import IconPencil from '../../../../components/svg-icon-components/icon-pencil';
import IconTime from '../../../../components/svg-icon-components/icon-time';
import {IOrder} from '../../../../interfaces/order';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {orderAttachSelector} from '../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../redux/store';
import {fetchDeleteOrderAttach} from '../../../../redux/thunks/order/remove-order-attach';
import {getIdUser} from '../../../../shared/utils/decoder-jwt';
import {renderRequester, renderStatus} from '../../../../shared/utils/order/order';
import {converterDatetimeString, converterTimeString} from '../../../../shared/utils/time/converter-time';
import {getTimeDisplayValue} from '../../../../shared/utils/time/get-time-display-value';
import styles from './order-details.module.css';

interface InterfaceOrderDetails {
  order: IOrder | null;
  className?: string;
  groupClassName?: string;
}

export const OrderDetails: FC<InterfaceOrderDetails> = ({order, className, groupClassName}) => {
  if (!order) {
    return;
  }

  const idUser = getIdUser();
  const orderAttach = useAppSelector(orderAttachSelector);
  const dispatch = useAppDispatch();

  const deleteOrderAttach = () => {
    const owner = 'Registered' in order.order_details.owned_by ? order.order_details.owned_by.Registered.id : order.order_details.owned_by.Anonym.id;
    dispatch(fetchDeleteOrderAttach(
      {
        inventory: order.service_info.service_id, platform_id: order.host_info.platform_id, order_id: order.id, owner,
      }));
  };

  return (
    <div className={classNames(styles.order_details, className)}>
      <h2>Подробнее о заказе</h2>
      <div className={styles.details}>
        <div className={classNames(styles.details_box, groupClassName)}>
          <h3>Тип заказа</h3>
          <ListWithIcons>
            <ListWithIcons.Item
              icon={<IconLocation />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>Номер заказа: {order.id}</span>
            </ListWithIcons.Item>

            <ListWithIcons.Item
              icon={<IconTime />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                {order.order_details.date ? `${converterTimeString(order.order_details.date) } ,` : 'Не указано'}{' '}
                {order.order_details.date && getTimeDisplayValue(
                  order.order_details.from,
                  order.order_details.from + order.order_details.duration,
                  true,
                )}
              </span>
            </ListWithIcons.Item>
            <ListWithIcons.Item
              icon={<IconCount />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                Количество: {order.order_details.amount} шт.
              </span>
            </ListWithIcons.Item>
            {!!order.order_details.description && (
              <ListWithIcons.Item
                icon={<IconPencil />}
                contentClassName={styles.list_item_content}
              >
                <span className={styles.label}>
                  {order.order_details.description}{' '}
                </span>
              </ListWithIcons.Item>
            )}
          </ListWithIcons>
        </div>
        <div className={classNames(styles.details_box, groupClassName)}>
          <h3>Информация о пользователе</h3>
          <ListWithIcons>
            {'Registered' in order.order_details.owned_by &&
              <ListWithIcons.Item
                icon={<IconCount />}
                contentClassName={styles.list_item_content}
              >
              <span className={styles.label}>
                {order.order_details.owned_by.Registered.name}
              </span>
              </ListWithIcons.Item>}
            <ListWithIcons.Item
              icon={<IconCount />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                {'Registered' in order.order_details.owned_by ? order.order_details.owned_by.Registered.phone : order.order_details.owned_by.Anonym.phone}
              </span>
            </ListWithIcons.Item>
            {'Registered' in order.order_details.owned_by &&
              <ListWithIcons.Item
                icon={<IconCount />}
                contentClassName={styles.list_item_content}
              >
              <span className={styles.label}>
                {order.order_details.owned_by.Registered.email}
              </span>
              </ListWithIcons.Item>}
          </ListWithIcons>
        </div>
        <div className={classNames(styles.details_box, groupClassName)}>
          <h3>Информация об услуге</h3>
          <ListWithIcons>
            <ListWithIcons.Item
              icon={<IconCount />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                Вид услуги: {order.service_info.service_type}
              </span>
            </ListWithIcons.Item>
          </ListWithIcons>
        </div>
        <div className={classNames(styles.details_box, groupClassName)}>
          <h3>Статус заказа</h3>
          <ListWithIcons>
            <ListWithIcons.Item
              icon={<IconTime />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                Статус заказа: {renderStatus(order)}
              </span>
            </ListWithIcons.Item>

            <ListWithIcons.Item
              icon={<IconCount />}
              contentClassName={styles.list_item_content}
            >
              <span className={styles.label}>
                Изменения: {renderRequester(order, idUser)}
              </span>
            </ListWithIcons.Item>

            {!!order.status_info.last_updated && (
              <ListWithIcons.Item
                icon={<IconDollar />}
                contentClassName={styles.list_item_content}
              >
                <span className={styles.label}>
                  Дата изменения:{' '}
                  {converterDatetimeString(order.status_info.last_updated)}
                </span>
              </ListWithIcons.Item>
            )}
          </ListWithIcons>
        </div>
      </div>
      {!!order.attached_info.attached && orderAttach &&
        <h3 className={styles.attached_orders_label}>Прикрепленные заказы</h3>}
      {!!order.attached_info.attached && orderAttach && orderAttach.map(i => {
        return <div className={styles.attached_orders}>
          <div className={styles.attached_orders_container}>
            <div className={styles.attached_orders_content}>
              <img className={styles.attached_orders_img} src={noImagePlaceholder} alt={i.service_info.service_type}/>
              <div className={styles.attached_orders_text_wrapper}>
                <h4 className={styles.attached_orders_title}>{i.service_info.service_type}</h4>
                <span className={styles.attached_orders_count}>Количество: {i.amount}</span>
              </div>
            </div>
            {/* <span className={styles.attached_orders_org_title}>{i.service_info.service_type}</span>*/}
            <button className={styles.button_remove} onClick={deleteOrderAttach}>
              <span>Удалить</span>
              <img className={styles.remove_icon} src={remove} alt="remove" />
            </button>
          </div>
        </div>;
      })}
    </div>
  );
};
