import styled from '@emotion/styled';

export const OrgInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: flex-start;
`;
export const OrgLogoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const OrgLogo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
`;
export const OrgName = styled.h3`
	color: var(--text-Strong, #303030);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;
export const Address = styled.h5`
	color: var(--text-Strong, #303030);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
