import {FC, memo} from 'react';

import {ButtonNew, Link} from '../../../../../components';
import {ADMIN, ADMIN_SETTING_PLATFORMS, UPDATE_ABONEMENT} from '../../../../../shared/constants/path';
import * as SC from './control-buttons.style';

interface Interface {
	clickUpdateAbonement: () => void;
	clickDeleteAbonement: () => void;
}

export const ControlButtons: FC<Interface> = memo(({
													   clickUpdateAbonement,
													   clickDeleteAbonement,
												   }) => {
	return (
		<SC.Container>
			<ButtonNew label={'Удалить'} onClick={clickDeleteAbonement} bgColor={'yellow'}/>
			<Link label={'Изменить'} path={`${ADMIN}${ADMIN_SETTING_PLATFORMS}${UPDATE_ABONEMENT}`} bgColor={'purple'} click={clickUpdateAbonement}/>
		</SC.Container>
	);
});
