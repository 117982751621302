import {FC, memo, ReactNode} from 'react';

import close_icon from '../../assets/icon/icon_cross.svg';
import * as SC from './modal-new.style';

interface Props {
	children: ReactNode;
	classNameWrapper?: string;
	classNameContainer?: string;
	onClickClose?: () => void;
}

export const ModalNew: FC<Props> = memo(({children, classNameWrapper, onClickClose, classNameContainer}) => {

	return (
		<SC.Window>
			<SC.Container className={classNameContainer}>
				<SC.ContentWrapper className={classNameWrapper}>
					{children}
					<SC.CloseButton onClick={onClickClose}>
						<SC.CloseIcon src={close_icon} alt={'close icon'}/>
					</SC.CloseButton>
				</SC.ContentWrapper>
			</SC.Container>
		</SC.Window>
	);
});
