import {FC, memo} from 'react';

import {AbonementType} from '../../../../../../interfaces/abonement';
import {convertPriceToString} from '../../../../../../shared/utils/converter-price';
import {orgLogoSmall} from '../../../../../../shared/utils/get-images-size';
import {ButtonWithBorder} from '../../../../../buttons-new';
import {getLimitAbonement, workingAbonementTo} from '../../../utils';
import {OrgInfo} from '../org-info/org-info';
import * as SC from './card-abonement.style';

interface Props {
	abonement: AbonementType;
	showMore: boolean;
	clickShowMore: () => void;
}

export const CardAbonement: FC<Props> = memo(({abonement, clickShowMore}) => {


	return (
		<SC.Container>
			<OrgInfo logo={orgLogoSmall(abonement.org_info.logo)} org_name={abonement.org_info.org_name}
					 address={abonement.platform_info.location_info.address}
					 city={abonement.platform_info.city}/>
			<SC.Name>{abonement.info.name}</SC.Name>
			<SC.AbonementPriceLimitWrapper>
				<SC.Price>{convertPriceToString(abonement.info.price)}</SC.Price>
				<SC.LimitWrapper>
					<SC.Limit>{getLimitAbonement(abonement.info.duration)}</SC.Limit>
					<SC.WorkTo>До {workingAbonementTo(abonement.info.duration)}</SC.WorkTo>
				</SC.LimitWrapper>
			</SC.AbonementPriceLimitWrapper>
			<ButtonWithBorder label={'Подробнее'} borderColor={'purple'} onClick={clickShowMore}/>
		</SC.Container>
	);
});

