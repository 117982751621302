import {FC, memo, useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import bonuses_icon from '../../../../../assets/icon/bonuses-order.svg';
import conditions_icon from '../../../../../assets/icon/conditions.svg';
import {AbonementType} from '../../../../../interfaces/abonement';
import {useHandleGoToLogin, useHandleGoToRegistration} from '../../../../../pages/orders/utils';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {servicesPlatformCheckedSelector} from '../../../../../redux/selectors/platforms-state-selectors';
import {dataUserBalanceSelector} from '../../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchGetConfig} from '../../../../../redux/thunks/payment/get-config';
import {fetchSetPayment} from '../../../../../redux/thunks/payment/set-payment';
import {fetchGetServicesByFilter} from '../../../../../redux/thunks/services/get-services-by-filter';
import {fetchGetUserById} from '../../../../../redux/thunks/user/get-user-by-id';
import {useResizeWindow} from '../../../../../shared/hooks/use-resize-window';
import {convertPriceToString} from '../../../../../shared/utils/converter-price';
import {getIdUser} from '../../../../../shared/utils/decoder-jwt';
import {orgLogoSmall} from '../../../../../shared/utils/get-images-size';
import {isAuthRightToken} from '../../../../../shared/utils/save-local-storage';
import {ButtonNew} from '../../../../buttons-new';
import {ButtonCheckbox} from '../../../../checkboxes/button-checkbox/button-checkbox';
import {Form, InputPhone} from '../../../../controls-new/input-phone/input-phone';
import {ModalNew} from '../../../../modal-new/modal-new';
import {BenefitRegister} from '../../components/benefit-register/benefit-register';
import {BonusesIconText} from '../../components/bonuses-icon-text/bonuses-icon-text';
import {ForPayment} from '../../components/for-payment/for-payment';
import {ServicesMoreOne} from '../../components/services-more-one/services-more-one';
import {Title} from '../../components/title/title';
import {
	bonusesAvailable, calculateFinishPrice, getLimitAbonement, workingAbonementTo
} from '../../utils';
import {DetailsAbonementAdaptive} from '../components/details-abonement-adaptive/details-abonement-adaptive';
import {OrgInfo} from '../components/org-info/org-info';
import * as SC from './modal-payments-abonement-more-one-service.style';

interface Props {
	onClickClose: () => void;
	abonement: AbonementType;
}

export const ModalPaymentsAbonementMoreOneService: FC<Props> = memo(({onClickClose, abonement}) => {
	const dispatch = useAppDispatch();
	const bonuses = useAppSelector(state => state.payment.config);
	const width = useResizeWindow();
	const idUser = getIdUser();
	const [useAllBonuses, setUseAllBonuses] = useState(false);
	const balance = useAppSelector(dataUserBalanceSelector);
	const price = abonement.info.price;
	const services = useAppSelector(servicesPlatformCheckedSelector);
	const metods = useForm<Form>({defaultValues: {phone: ''}});
	const {register, watch} = metods;
	const phoneRegex = /^\+375(29|33|44|25)\d{7}$/;
	const isValidPhone = watch('phone').length !== 13 || !phoneRegex.test(watch('phone'));

	useEffect(() => {
		dispatch(fetchGetConfig());
		dispatch(fetchGetServicesByFilter({abonement_id: [abonement.id], allow_view_only: true}));
		if (isAuthRightToken) {
			dispatch(fetchGetUserById(idUser));
		}
	}, []);

	const clickUseAllBonuses = useCallback(() => setUseAllBonuses(prevState => !prevState), []);

	const onClickBye = useCallback(() => {
		dispatch(fetchSetPayment({
			issuer: {Abonement: {id: abonement.id}},
			provider: 'BePaid',
			requester: isAuthRightToken ? {Registered: {id: idUser}} : {Anonym: {phone: watch('phone')}},
			success_url: `${window.location.protocol}//${window.location.host}/payment-abonement-success/${abonement.id}`,
			fail_url: `${window.location.protocol}//${window.location.host}/payment-abonement-fail/${abonement.id}`,
			used_bonus_amount: (useAllBonuses && balance > 0) ? (price >= balance ? balance : price) : undefined,
		}));
	}, [useAllBonuses, abonement.id, price, balance, isAuthRightToken]);

	const handleGoToRegistration = useCallback(() => useHandleGoToRegistration(dispatch), []);

	const clickGoLogin = useCallback(() => useHandleGoToLogin(dispatch), []);

	return (
		<ModalNew classNameContainer={SC.Modal} onClickClose={onClickClose}>
			<Title title={'Оплата абонемента'}/>
			{width > 760 ? <SC.AbonementInfoWrapper>
					<OrgInfo city={abonement.platform_info.city} org_name={abonement.org_info.org_name}
							 address={abonement.platform_info.location_info.address}
							 logo={orgLogoSmall(abonement.org_info.logo)}/>
					<SC.Name>{abonement.info.name}</SC.Name>
					{services.length > 0 && <ServicesMoreOne working_to={abonement.info.working_to}
									  working_from={abonement.info.working_from} limit={abonement.info.limit}/>}
					<SC.TimeContainer>
						<SC.TimeWrapper>
							<SC.WorkToWrapper>
								<SC.TimeLabel>Длительность <SC.Time>
									{getLimitAbonement(abonement.info.duration)}
								</SC.Time>
								</SC.TimeLabel>
								<SC.Time>До {workingAbonementTo(abonement.info.duration)}</SC.Time>
							</SC.WorkToWrapper>
							<SC.PriceAbonement>Стоимость {convertPriceToString(price)}</SC.PriceAbonement>
						</SC.TimeWrapper>
						<SC.IconBonusesWrapper>
							<img src={bonuses_icon} alt="bonuses icon"/>
							<BonusesIconText bonuses={bonuses.rewards.payment_reward}/>
						</SC.IconBonusesWrapper>
						{isAuthRightToken && <SC.BunusesWrapper>
							<SC.HaveBonuses>Доступно {bonusesAvailable({
								useAllBonuses, balance, price
							})}</SC.HaveBonuses>
							<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'} name={'bonuses'}
											value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
							<SC.ResultPrice>
								<span>Итого</span>
								<SC.Price>{calculateFinishPrice({price, balance, useAllBonuses})}</SC.Price>
							</SC.ResultPrice>
						</SC.BunusesWrapper>}
					</SC.TimeContainer>
					{!isAuthRightToken && <FormProvider {...metods}>
						<InputPhone keyForm={'phone'} {...register('phone')}/>
					</FormProvider>}
					<SC.TermsVisiting>
						<SC.TitleWrapperConditions>
							<img src={conditions_icon} alt="условия посещения"/>
							<SC.TitleConditions>Условия посещения</SC.TitleConditions>
						</SC.TitleWrapperConditions>
						<SC.RecommendationConditions>Для комфортных тренировок необходимо бронировать посещение заранее.
							Это можно сделать в разделе "Мои заказы" вашего личного кабинета.</SC.RecommendationConditions>
					</SC.TermsVisiting>
					<SC.ButtonPaymentWrapper>
						<ForPayment/>
						<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickBye}
								   disabled={isAuthRightToken ? false : isValidPhone}/>
					</SC.ButtonPaymentWrapper>
					{!isAuthRightToken &&
						<BenefitRegister clickLogin={clickGoLogin} handleGoToRegistration={handleGoToRegistration}/>}
				</SC.AbonementInfoWrapper>
				: <DetailsAbonementAdaptive abonement={abonement} balance={balance} services={services}
											clickUseAllBonuses={clickUseAllBonuses} useAllBonuses={useAllBonuses}
											payment_reward={bonuses.rewards.payment_reward} onClickBye={onClickBye}/>}
		</ModalNew>
	);
});
