import styled from '@emotion/styled';

export const Textarea = styled.textarea`
	display: flex;
	height: 81px;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 4px;
	border: 2px solid var(--border-Weak, #CFCFCF);
	outline: none;
	transition: 0.3s;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	color: var(--text-Strong, #303030);
	width: 100%;

	&:focus {
		border: 2px solid var(--border-B);
		&::placeholder {
			color: transparent;
		}
	}

	&::placeholder {
		color: var(--text-Weak, #4D4D4D);
	}
`;
export const TextareaContainer = styled.div`
	width: 100%;
	position: relative;

	textarea:focus {
		label{
			display: block;
		}
	}
`;
export const Label = styled.label`
	display: none;
	position: absolute;
	top: -8px;
	left: 12px;
	z-index: 1;
	background-color: var(--white-color);
	padding: 2px 8px;
	color: var(--text-B, #352C8D);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 14px;
`;
