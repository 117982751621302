import styled from '@emotion/styled';

export const OrderInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	*{
		border-bottom: none !important;
	}
`;

export const Sports = styled.p`
	color: var(--text-Strong);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
export const Date = styled.span`
	color: var(--text-Strong);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const StatusWrapper = styled.div`
	display: flex;
	gap: 6px;
`;
export const Status = styled.span`
	color: var(--text-Weak);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
`;
export const Price = styled.span`
	color: var(--text-Weak);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	margin-top: 8px;
`;
