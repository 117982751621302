import {FC} from 'react';

import {AbonementType} from '../../../../../../interfaces/abonement';
import {ServiceListItem} from '../../../../../../interfaces/service';
import {SERVICE_PAGE} from '../../../../../../shared/constants/path';
import {LinkWithLineBottom} from '../../../../../links-navigate';
import * as SC from './services-abonement-adaptive.style';

interface Props {
	abonement: AbonementType;
	services: ServiceListItem[]
}

export const ServicesAbonementAdaptive: FC<Props> = ({abonement, services}) => {

	return (
		<SC.Container>
			<SC.Name>{abonement.info.name}</SC.Name>
			<SC.Address>{abonement.platform_info.city ? `${abonement.platform_info.city}, ` : ''}{abonement.platform_info.location_info.address}</SC.Address>
			<SC.ServicesWrapper>
				{services.map(i=> {
					return <SC.Service>
						<SC.ServiceType>{i.service_type}</SC.ServiceType>
						<SC.Sports>{i.sports.join(', ')}</SC.Sports>
						<LinkWithLineBottom label={'Подробнее об услуге'} path={`${SERVICE_PAGE}?service_id=${i.id}`}/>
					</SC.Service>;
				})}
			</SC.ServicesWrapper>
		</SC.Container>
	);
};
