import {FC, memo} from 'react';

import not_logo from '../../../../../../assets/img/no_image.png';
import * as SC from './org-info.style';

interface Props {
	logo: string | undefined;
	org_name: string;
	address: string;
	city: string | null;
}

export const OrgInfo:FC<Props> = memo(({org_name, logo, city, address}) => {
	return (
		<SC.OrgInfo>
			<SC.OrgLogoWrapper>
				<SC.OrgLogo src={logo || not_logo} alt={org_name}/>
				<SC.OrgName>{org_name}</SC.OrgName>
			</SC.OrgLogoWrapper>
			<SC.Address>{city ? `${city}, ` : ''}{address}</SC.Address>
		</SC.OrgInfo>
	);
});
