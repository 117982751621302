import {FC} from 'react';

import {AbonementType} from '../../../../../interfaces/abonement';
import {ButtonNew} from '../../../../buttons-new';
import {ButtonCheckbox} from '../../../../checkboxes/button-checkbox/button-checkbox';
import {ForPayment} from '../../components/for-payment/for-payment';
import {bonusesAvailable, calculateFinishPrice} from '../../utils';
import {CardAbonement} from '../components/card-abonement/card-abonement';
import * as SC from './one-service-adaptive.style';

interface Props {
	abonement: AbonementType;
	balance: number;
	showMore: boolean;
	useAllBonuses: boolean;
	onClickPayment: () => void;
	clickShowMore: () => void;
	clickUseAllBonuses: () => void;
}

export const OneServiceAdaptive: FC<Props> = ({
												  abonement, balance, onClickPayment, showMore, clickShowMore,
												  useAllBonuses, clickUseAllBonuses
											  }) => {

	return (
		<>
			<CardAbonement abonement={abonement} showMore={showMore} clickShowMore={clickShowMore}/>
			<SC.BonusesWrapper>
				<SC.HasBalance>Доступно {bonusesAvailable({
					useAllBonuses, price: abonement.info.price, balance
				})}</SC.HasBalance>
				<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'} name={'bonuses'}
								value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
				<SC.ResultPrice>
					<SC.HasBalance>Итого</SC.HasBalance>
					<SC.Price>{calculateFinishPrice({price: abonement.info.price, balance, useAllBonuses})}</SC.Price>
				</SC.ResultPrice>
			</SC.BonusesWrapper>
			<SC.ButtonPaymentWrapper>
				<ForPayment/>
				<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickPayment}/>
			</SC.ButtonPaymentWrapper>
		</>
	);
};
