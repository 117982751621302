import axios from 'axios';

import {HOST_PAYMENT, urlAddPayment, urlConfig, urlGetPayment} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {SetPayment} from './query-params';

const instance = axios.create({
	baseURL: HOST_PAYMENT,
});
const instanceJwt = axios.create({
	baseURL: HOST_PAYMENT,
	headers: {
		'Content-Type': 'application/json',
	},
});
instanceJwt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
);

export const SearchPayment = {
	setPayment(params: SetPayment) {
		return instance.post(urlAddPayment, params);
	},
	getPayment(id: string) {
		return instanceJwt.get(`${urlGetPayment}/${id}`);
	},
	getConfig() {
		return instance.get(urlConfig);
	}
};
