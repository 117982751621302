import {FC, memo} from 'react';

import * as SC from './radio.style';

interface Props {
	value: string;
	label: string;
	checked: boolean;
	onChange: () => void;
}

export const Radio: FC<Props> = memo(({value, label, checked, onChange}) => {

	return (
		<SC.FormRadio>
			<input
				id={value} type="radio" checked={checked}
				name="radio" value={value} onChange={onChange}/>
			<SC.Label htmlFor={value}>{label}</SC.Label>
		</SC.FormRadio>
	);
});
