import React, {FC, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {IOrder} from '../../../../interfaces/order';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {useAppDispatch} from '../../../../redux/store';
import {fetchGetConfig} from '../../../../redux/thunks/payment/get-config';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {InputPhone} from '../../../controls-new/input-phone/input-phone';
import {ModalNew} from '../../../modal-new/modal-new';
import {ForPayment} from '../components/for-payment/for-payment';
import {OrgInfo} from '../components/org-info/org-info';
import {Title} from '../components/title/title';
import * as SC from './modal-anonim-payment.style';

interface Props {
	order: IOrder;
}

export const getBonusesWordIcon = (count: number) => {
	if (count % 100 >= 11 && count % 100 <= 14) {
		return `${count} бонусов`;
	} else if (count % 10 === 1) {
		return `${count} бонус`;
	} else if (count % 10 >= 2 && count % 10 <= 4) {
		return `${count} бонуса`;
	}
	return `${count} бонусов`;

};
export const getBonusesWord = (count: number) => {
	if (count % 100 >= 11 && count % 100 <= 14) {
		return `Начислим вам ${count} бонусов после визита`;
	}
	if (count % 10 === 1) {
		return `Начислим вам ${count} бонус после визита`;
	}
	if (count % 10 >= 2 && count % 10 <= 4) {
		return `Начислим вам ${count} бонуса после визита`;
	}
	return `Начислим вам ${count} бонусов после визита`;
};

export const ModalAnonimPayment: FC<Props> = ({order}) => {
	const metods = useForm();
	const {register} = metods;
	const dispatch = useAppDispatch();
	const bonuses = useAppSelector(state => state.payment.config);

	useEffect(() => {
		dispatch(fetchGetConfig());
	}, []);

	return (
		<ModalNew>
			<Title title={'Оплата услуги'}/>
			<OrgInfo org_logo={order.host_info.org_logo} org_name={order.host_info.organization}
					 address={`${order.host_info.city}, ${order.host_info.location_info.address}`}/>
			<SC.OrderInfoWrapper>
				<SC.RecommendationWrapper>
					<SC.TextContentWrapper>
						<SC.RecommendationTitle>Зарегистрируйтесь
							и получите больше возможностей! </SC.RecommendationTitle>
						<SC.Recommendation>Бонусы, история заказов, управление бронированиями, оплата онлайн
							и эксклюзивные предложения – все это доступно после быстрой регистрации.</SC.Recommendation>
					</SC.TextContentWrapper>
					<ButtonNew label={'Зарегестрироваться'} bgColor={'green'} className={SC.Button}/>
				</SC.RecommendationWrapper>
			</SC.OrderInfoWrapper>
			<FormProvider {...metods}>
				<InputPhone {...register('phone')} keyForm={'phone'}/>
			</FormProvider>
			<SC.Bonuses>Начислим {getBonusesWordIcon(bonuses.rewards.payment_reward / 100)} за оплату </SC.Bonuses>
			<SC.FooterModalWrapper>
				<ForPayment/>
				<SC.ButtonWrapper>
					<ButtonWithBorder label={'Отменить'} borderColor={'yellow'} className={SC.Button}/>
					<ButtonNew label={'Оплатить'} className={SC.Button}/>
				</SC.ButtonWrapper>
			</SC.FooterModalWrapper>
		</ModalNew>
	);
};
