import {RootState} from '../store';

export const checkedPlatformSelector = (state: RootState) => state.fieldsState.checkedPlatform;
export const allPlatformsSelector = (state: RootState) => state.fieldsState.allPlatform;
export const filteredServicesSelector = (state: RootState) => state.fieldsState.filteredServices;
export const servicesTotalSelector = (state: RootState) => state.fieldsState.servicesTotalPlatform;
export const servicesPlatformCheckedSelector = (state: RootState) => state.fieldsState.servicesPlatformChecked;
export const servicesUpdatePlatformCheckedSelector = (state: RootState) => state.fieldsState.checkedPlatformUpdate;
export const workloadServiceSelector = (state: RootState) => state.fieldsState.workloadService;
export const selectServicesTotal = (state: RootState) => state.fieldsState.servicesTotal;
export const selectTotalServicesOrigins = (state: RootState) => state.fieldsState.totalServicesOrigins;
export const selectTotalServicesDepsAllowed = (state: RootState) => state.fieldsState.totalServicesDepsAllowed;
export const selectServicesTotalDeps = (state: RootState) => state.fieldsState.servicesTotalDeps;
export const selectPlatformsTotal = (state: RootState) => state.fieldsState.platformsTotal;
export const idCheckServiceIdSelector = (state: RootState) => state.fieldsState.isCheckServiceId;
export const notificationSelector = (state: RootState) => state.fieldsState.notification;
export const platformSelector = (state: RootState) => state.fieldsState.platform;
