import {Link} from 'react-router-dom';

import logo from '../../../assets/icon/logo-sporder.svg';
import payments from '../../../assets/img/payments.png';
import {
	ABOUT_PROJECT, ALL_ORG, CONTACTS, DOCS, MAIN, ORDER, PAYMENT_METHODS, PLAY_FIELDS, PROFILE_USER, QUOTE_PAGE
} from '../../../shared/constants/path';
import {SERVICES_NAV_LINK_CONFIG} from '../../../shared/constants/service/nav';
import * as SC from './footer-new.style';

export const FooterNew = () => {
	const year = (new Date()).getFullYear();

	return (
		<SC.FooterContainer>
			<SC.NavFooterWrapper>
				<SC.NavFooter>
					<SC.BlockNav>
						<SC.Title>Категории</SC.Title>
						<SC.ListLink>
							<li><SC.Link to={ALL_ORG}>Спортклубы</SC.Link></li>
							<li><SC.Link to={PLAY_FIELDS}>Залы</SC.Link></li>
							{SERVICES_NAV_LINK_CONFIG.map(({value, label}) => (
								<li key={value}>
									<SC.Link to={value}>
										{label}{' '}
									</SC.Link>
								</li>
							))}
						</SC.ListLink>
					</SC.BlockNav>
					<SC.BlockNav>
						<SC.Title>Быстрые ссылки</SC.Title>
						<SC.ListLink>
							<li><SC.Link to={ABOUT_PROJECT}>О проекте</SC.Link></li>
							<li><SC.Link to={DOCS}>Договор оферты</SC.Link></li>
							<li><SC.Link to={PAYMENT_METHODS}>Оплата услуг</SC.Link></li>
							<li><SC.Link to={ORDER}>Услуги</SC.Link></li>
							<li><SC.Link to={PROFILE_USER}>Личный кабинет</SC.Link></li>
						</SC.ListLink>
					</SC.BlockNav>
					<SC.BlockNav>
						<SC.TitleContacts>Контакты</SC.TitleContacts>
						<SC.ListLinkContacts>
							<li><SC.Link to={CONTACTS}>Связаться с нами</SC.Link></li>
							<li><SC.Link to={QUOTE_PAGE}>Партнерам</SC.Link></li>
						</SC.ListLinkContacts>
					</SC.BlockNav>
				</SC.NavFooter>
				<Link to={MAIN}>
					<img src={logo} alt={'логотип Sporder.by'}/>
				</Link>
			</SC.NavFooterWrapper>
			<SC.ScrollContainer>
				<SC.Payments src={payments} alt="payments" loading="lazy"/>
				<SC.Payments src={payments} alt="payments" loading="lazy"/>
			</SC.ScrollContainer>
			<SC.CompanyDetailsContainer>
				<SC.CompanyDetailsWrapper>
					<SC.TitleCompany>ООО "Румики"</SC.TitleCompany>
					<SC.CompanyAddress>г. Минск, ул. Максима Горецкого, дом 14, пом. 503, кабинет 2-42, +375255214757,
						sporder.business@gmail.com
						УНП 193756214, выдан Минским горисполкомом 2 апреля 2024 года
						Время работы: с 10:00 до 17:00</SC.CompanyAddress>
				</SC.CompanyDetailsWrapper>
				<SC.RightsReserved> &#169; {year} Sporder. Все права защищены</SC.RightsReserved>
			</SC.CompanyDetailsContainer>
		</SC.FooterContainer>
	);
};
