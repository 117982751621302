import {FC, memo, useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import bonuses_anonim from '../../../../assets/icon/bonuses-anonim.svg';
import {OrderCreateFieldKey, TOrderClaimCreate} from '../../../../interfaces/order';
import {ServiceListItem, ServiceSingle} from '../../../../interfaces/service';
import {formatDateString} from '../../../../pages/order-page/utils/convert-date';
import {useHandleGoToLogin, useHandleGoToRegistration} from '../../../../pages/orders/utils';
import {onOrderAnonym} from '../../../../pages/schedule-page/utils/order-create';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {useAppDispatch} from '../../../../redux/store';
import {fetchGetConfig} from '../../../../redux/thunks/payment/get-config';
import {converterTime, converterTimeLocalString} from '../../../../shared/utils/time/converter-time';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {Form, InputPhone} from '../../../controls-new/input-phone/input-phone';
import {ModalNew} from '../../../modal-new/modal-new';
import {AddBonuses} from '../components/add-bonuses/add-bonuses';
import {BenefitRegister} from '../components/benefit-register/benefit-register';
import {BonusesIconText} from '../components/bonuses-icon-text/bonuses-icon-text';
import {OrderDetail} from '../components/order-detail/order-detail';
import {OrgInfo} from '../components/org-info/org-info';
import {RulesOrders} from '../components/rules-orders/rules-orders';
import {Title} from '../components/title/title';
import {FastOrderSuccess} from '../fast-order-success/fast-order-success';
import {getBonusesWord} from '../modal-anonim-payment/modal-anonim-payment';
import {SuccessOrderAmonim} from '../success-order-amonim/success-order-amonim';
import * as SC from './modal-anonim-order.style';

interface Props {
	service: ServiceListItem | ServiceSingle;
	onClickClose: () => void;
	date: Date;
	from: number;
	duration: number;
	price: string;
	trial: boolean;
}

export const ModalAnonimOrder: FC<Props> = memo(({
													 service, onClickClose, date, from, duration, price, trial,
												 }) => {
	const [isSuccessOrder, setIsSuccessOrder] = useState<string | undefined>(undefined);
	const metods = useForm<Form>({defaultValues: {phone: ''}});
	const {register, watch} = metods;
	const phoneRegex = /^\+375(29|33|44|25)\d{7}$/;
	const isValidPhone = watch('phone').length !== 13 || !phoneRegex.test(watch('phone'));
	const dispatch = useAppDispatch();
	const bonuses = useAppSelector(state => state.payment.config);
	const time = `${formatDateString(date)} ${converterTime(from)} - ${converterTime(from + duration)}`;

	const successOrder = (value: string) => setIsSuccessOrder(value);

	const params: TOrderClaimCreate = {
		[OrderCreateFieldKey.from]: from,
		[OrderCreateFieldKey.service_id]: service.id,
		[OrderCreateFieldKey.date]: converterTimeLocalString(date),
		[OrderCreateFieldKey.duration]: duration + (service.order_policy.periodic?.min_order_duration || 0),
		[OrderCreateFieldKey.amount]: 1,
		[OrderCreateFieldKey.attached]: [{service_id: service.id, amount: 0}],
		[OrderCreateFieldKey.description]: '',
		[OrderCreateFieldKey.platform_id]: service.platform_info.platform_id,
		[OrderCreateFieldKey.trial_order]: trial,
		[OrderCreateFieldKey.requester]: {Anonym: {phone: watch('phone')}}
	};
	const onCreateOrderAnonym = () => {
		onOrderAnonym({service, successOrder, phone: watch('phone'), dispatch, params,});
	};

	useEffect(() => {
		dispatch(fetchGetConfig());
	}, []);

	const setSessionStorage = () => {
		sessionStorage.setItem('params_order', JSON.stringify(params));
		sessionStorage.setItem('service_id_order', service.id);
		sessionStorage.setItem('params_order_allows', service.order_policy.allows);
	};

	const handleGoToRegistration = useCallback(() => {
		useHandleGoToRegistration(dispatch);
		setSessionStorage();
	}, []);

	const clickLogin = useCallback(() => {
		onClickClose();
		useHandleGoToLogin(dispatch);
		setSessionStorage();
	}, []);

	return (
		<>
			{isSuccessOrder === 'claim' && <SuccessOrderAmonim onClickClose={onClickClose}/>}
			{isSuccessOrder === 'book' &&
				<FastOrderSuccess onClickClose={onClickClose} service={service} price={price} time={time}
								  goToLogin={clickLogin} goToRegistration={handleGoToRegistration}
								  payment_reward={bonuses.rewards.payment_reward}
								  order_reward={bonuses.rewards.order_reward}/>}
			{(isSuccessOrder !== 'claim' && isSuccessOrder !== 'book') &&
				<ModalNew onClickClose={onClickClose} classNameWrapper={SC.Modal}
						  classNameContainer={SC.ModalContainer}>
					<Title title={'Бронирование услуги'}/>
					<SC.OrgInfoContainer>
						<OrgInfo org_logo={service.org_info.logo} org_name={service.org_info.org_name}
								 address={`${service.platform_info.city}, ${service.platform_info.location_info.address}`}/>
						<SC.ServiceType>{service.service_type}</SC.ServiceType>
						<SC.OrderInfoWrapper>
							<OrderDetail detailLabel={'Вид спорта'}
										 children={<SC.ServiceType>{service.sports.join(', ')}</SC.ServiceType>}/>
							<OrderDetail detailLabel={'Дата и Время'} children={<SC.Data>{time}</SC.Data>}/>
						</SC.OrderInfoWrapper>
						<SC.Price>{price}</SC.Price>
					</SC.OrgInfoContainer>
					<SC.RecommendationContainer>
						<SC.BonusesIconWrapper>
							<img src={bonuses_anonim} alt={'bonuses'}/>
							<BonusesIconText bonuses={bonuses.rewards.order_reward}/>
						</SC.BonusesIconWrapper>
						<BenefitRegister handleGoToRegistration={handleGoToRegistration} clickLogin={clickLogin}/>
					</SC.RecommendationContainer>
					<SC.InputWrapper>
						<FormProvider {...metods}>
							<InputPhone {...register('phone')} keyForm={'phone'}/>
						</FormProvider>
						<AddBonuses text={`${getBonusesWord(bonuses.rewards.order_reward / 100)}`}/>
					</SC.InputWrapper>
					<SC.ButtonsWrapper>
						<SC.ButtonsRow>
							<ButtonWithBorder label={'Отменить'} borderColor={'yellow'}/>
							<ButtonNew label={'Забронировать'} onClick={onCreateOrderAnonym}
									   disabled={isValidPhone}/>
						</SC.ButtonsRow>
						<RulesOrders/>
					</SC.ButtonsWrapper>
				</ModalNew>}
		</>
	);
});
