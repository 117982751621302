import {css} from '@emotion/css';
import styled from '@emotion/styled';

interface ServiceSportTypeStyle{
	changeFont: boolean;
}

interface ServiceCardStyle{
	activeCard: boolean;
}
export const ServiceCard = styled.div<ServiceCardStyle>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid var(--green);
	background-color: ${props => props.activeCard ? 'var(--background-Alternative)' : 'rgba(101,101,101,0.32)'};
	color: ${props => props.activeCard ? 'var(--title)' : '#262626'};
`;
export const HeaderCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 6px;
	height: 116px;
`;
export const ServiceTypeTitle = styled.h2<ServiceSportTypeStyle>`
	color: var(--title-card-service);
	font-size: ${props => props.changeFont ? 24 : 18}px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	white-space: nowrap;
	max-width: 100%;
	height: 56px;
	text-wrap: wrap;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
`;

export const DataServiceHeader = styled.h4`
	color: var(--title-card-service);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Logo = styled.img`
	width: 100%;
	aspect-ratio: 16/9;
	object-fit: cover;
	border-radius: 4px;
`;
export const OrgInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
`;
export const OrgLogo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
`;
export const OrgName = styled.h3`
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const PlatformLogoWrapper= styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const Address = styled.span`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	width: fit-content;
`;
export const TypeServiceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	height: 62px;
	width: 100%;
`;
export const ServiceType = styled.div`
	display: flex;
	align-items: end;
	justify-content: space-between;
	width: 100%;
`;
export const ServiceTypeLabel = styled.span`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Type = styled.span`
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;

export const Price = styled.span`
	color: var(--green);
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px;
`;
export const HowOrder = styled.p`
	color: var(--text-Strong);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`;
export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 62px;
	justify-content: center;
`;
export const Button = css`
	padding: 8px 24px;
`;
