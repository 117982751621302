import {FC, memo} from 'react';

import {useAppSelector} from '../../../../../redux/selectors/hook';
import {servicesPlatformCheckedSelector} from '../../../../../redux/selectors/platforms-state-selectors';
import {converterTime} from '../../../../../shared/utils/time/converter-time';
import {getLimitClassesAbonement} from '../../utils';
import * as SC from './services-more-one.style';

interface Props {
	limit: number | null;
	working_to: number | null;
	working_from: number | null;
}

export const ServicesMoreOne: FC<Props> = memo(({limit, working_from, working_to}) => {
	const services = useAppSelector(servicesPlatformCheckedSelector);

	return (
		<SC.ServicesWrapper>
			<SC.Services>
				{services.map(i => {
					return <SC.ServiceTypeWrapper key={i.id}>
						<SC.DetailService>{i.service_type}</SC.DetailService>
						<SC.Sports>{i.sports.join(', ')}</SC.Sports>
					</SC.ServiceTypeWrapper>;
				})}
			</SC.Services>
			<SC.DetailService>{getLimitClassesAbonement(limit)}</SC.DetailService>
			{(working_from && working_to) ?
				<SC.DetailService>с {converterTime(working_from)} до {converterTime(working_to)}</SC.DetailService>
				: <SC.DetailService>Весь день</SC.DetailService>}
		</SC.ServicesWrapper>
	);
});
