import {FC, memo} from 'react';

import * as SC from './label-detail.style';

interface Props {
	label: string;
}

export const LabelDetail:FC<Props> = memo(({label}) => {
	return (
		<SC.LabelDetail>{label}</SC.LabelDetail>
	);
});
