import {createAsyncThunk} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {SearchPayment} from '../../../api/search-payment';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetConfig = createAsyncThunk(
	'paymentSlice/fetchGetConfig',
	async (_, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPayment.getConfig();
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data};
			}
			return {status: response.status, data: response.data};
		} catch (error) {
			if (isAxiosError(error)) {
				return handleAxiosError({dispatch, rejectWithValue, error});
			}
		}
	},
);
