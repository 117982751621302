import styled from '@emotion/styled';

export const ServicesWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 16px;
`;
export const Services = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-S, 4px);
`;
export const ServiceTypeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;
	width: 280px;
`;
export const Sports = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 14px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const DetailService = styled(Sports)`
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;
