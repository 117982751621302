import {FC, memo, ReactNode} from 'react';

import * as SC from './button-new.style';

interface Props {
	onClick?: () => void;
	label: string;
	bgColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	icon?: ReactNode;
	disabled?: boolean;
	iconPosition?: 'left' | 'right';
}

export const ButtonNew: FC<Props> = memo(({
											  bgColor = 'green', onClick, label, className, disabled, icon,
											  iconPosition = 'right',
										  }) => {
	return (
		<SC.Container bgColor={bgColor} onClick={onClick} className={className}
					  disabled={disabled}>
			{(icon && iconPosition === 'left') && icon}
			{label}
			{(icon && iconPosition === 'right') && icon}
		</SC.Container>
	);
});
