import styled from '@emotion/styled';

export const Link = styled.a`
	color: var(--text-B);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	border-bottom: 1px solid var(--text-B);
`;
