import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {AllOrdersParams} from '../../../api/query-params';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setHistoryOrders} from '../../reducers/user-data-reducer';

export const fetchGetHistoryOrdersUser = createAsyncThunk(
	'userDataSlice/fetchGetHistoryOrdersUser',
	async (params: AllOrdersParams, {dispatch, rejectWithValue}) => {
		try {
			const response = await crmPlatformAPI.getOrders(params);

			if (response.status === 200) {
				dispatch(setHistoryOrders({data: response.data.orders}));
				return {status: response.status, data: response.data.orders};
			}
			if (response.status === 204) {
				dispatch(setHistoryOrders({data: []}));
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
