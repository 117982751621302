import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 16px;
	@media screen and (max-width: 480px) {
		flex-direction: column;
	}
`;
export const Button = css`
	width: 100%;
	padding: 8px;
`;
export const FooterModal = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const AvailableBonuses = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-end;
	color: var(--text-Weak);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Bonuses = styled.span`
	color: #1B1B1B;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const FinishPriceWrapper = styled.div`
	color: var(--text-Weak);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	text-align: end;
`;
export const FinishPrice = styled.span`
	color: var(--text-G);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;

export const OrderInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 8px;
	@media screen and (max-width: 590px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`;
export const OrderInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: flex-end;
	@media screen and (max-width: 590px) {
		align-items: flex-start;
	}
`;
export const BonusesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: var(--Spacing-desctop-Body-M) var(--Spacing-desctop-Block-S);
	gap: var(--Spacing-desctop-Body-M);
	border-radius: var(--Corner-S);
	border: 1px solid var(--border-Neutral);
	width: 224px;
`;
