import styled from '@emotion/styled';
interface TextContentWrapperStyle{
	isAdminPage?: boolean
	active: boolean
}
export const TextContentWrapper = styled.div<TextContentWrapperStyle>`
	background-color: var(--background-Alternative);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	cursor: default;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	gap: 10px;
	line-height: 1.3em;
	padding: ${props => props.isAdminPage ? '10px 12px 46px' : '10px 12px 22px'};
	margin: 0 6px;
	color: var(--link-color);
	${props=> !props.active && `
	opacity: 0.5;
    pointer-events: none;
	`}
`;
export const Description = styled.p`
	-webkit-box-orient: vertical;
	display: -webkit-box;
	font-size: 14px;
	height: 60px;
	line-height: 1.385em;
	overflow: auto;
	white-space: pre-wrap;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #9f9f9f;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eaeaea;
		border-radius: 10px;
	}
`;
export const AbonementPriceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const IconPriceWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	white-space: pre-wrap;
	text-overflow: ellipsis;
`;
