import styled from '@emotion/styled';

export const Header = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
	padding: var(--Spacing-desctop-Block-S, 16px) 0;
    color: var(--text-blue);
    background: var(--button-Secondary);
	border-bottom: 1px solid var(--border-Neutral, #CFCFCF);
	margin-bottom: var(--Spacing-desctop-Body-M, 16px);
`;

export const HeaderContent = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1410px;
	margin: 0 auto;

	@media screen and (max-width: 1130px) {
		flex-direction: column;
		gap: 12px;
	}

`;
export const HeaderControls= styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	@media screen and (max-width: 1130px){
		width: 100%;
		justify-content: flex-end;
	}
`;

export const HeaderContentAdaptive = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid var(--border-Neutral, #CFCFCF);
	@media screen and (max-width: 600px) {
		border: none;
	}
`;
