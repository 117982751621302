import styled from '@emotion/styled';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--Spacing-desctop-Block-S, 16px);
	align-self: stretch;
`;
export const Name = styled.h2`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const Address = styled.h3`
	color: var(--text-Strong, #303030);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
`;
export const ServicesWrapper = styled.ul`
	display: flex;
	padding: 0 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-S, 4px);
	align-self: stretch;
`;
export const Service = styled.li`
	display: flex;
	padding: 4px 0;
	flex-direction: column;
	align-items: center;
	gap: var(--Spacing-desctop-Body-S, 4px);
	align-self: stretch;
`;
export const ServiceType = styled.h4`
	color: var(--text-Strong, #303030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Sports = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 14px;
`;
