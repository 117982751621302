import {FC, useCallback} from 'react';

import {useHandleGoToRegistration} from '../../../../pages/orders/utils';
import {useAppDispatch} from '../../../../redux/store';
import {REGISTER} from '../../../../shared/constants/path';
import {ButtonNew} from '../../../buttons-new';
import {LinkWithBorder} from '../../../links-navigate';
import {ModalNew} from '../../../modal-new/modal-new';
import {Title} from '../components/title/title';
import * as SC from './success-order-amonim.style';

interface Props{
	onClickClose: () => void;
}

export const SuccessOrderAmonim:FC<Props> = ({onClickClose}) => {
	const dispatch = useAppDispatch();

	const handleGoToRegistration = useCallback(() => {
		useHandleGoToRegistration(dispatch);
	}, []);

	return (
		<ModalNew onClickClose={onClickClose}>
			<SC.TitleWrapper>
				<Title title={'Данные сохранены'}/>
				<SC.ResultOrder>Заказ успешно офромлен.
					Ожидайте звонка администратора для уточнения деталей заказа</SC.ResultOrder>
				<ButtonNew label={'Ok!'} onClick={onClickClose} bgColor={'purple'} className={SC.Button}/>
			</SC.TitleWrapper>
			<SC.ThankYouMessage>Благодарим за использование сервиса Sporder.by</SC.ThankYouMessage>
			<SC.OfferWrapper>
				<SC.OfferTitle>Зарегистрируйтесь
					и получите больше возможностей! </SC.OfferTitle>
				<SC.Presents>Бонусы, история заказов, управление бронированиями, оплата онлайн
					и эксклюзивные предложения –
					все это доступно после быстрой регистрации.</SC.Presents>
				<LinkWithBorder label={'Зарегестрироваться'} path={REGISTER} onClick={handleGoToRegistration}/>
			</SC.OfferWrapper>
		</ModalNew>
	);
};
