import {DetailedHTMLProps, forwardRef, TextareaHTMLAttributes} from 'react';

import * as SC from './textarea-new.style';

interface Props extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	className?: string;
	label?: string;
}

export const TextareaNew = forwardRef<HTMLTextAreaElement, Props>(function ({className, label, ...restProps}, ref) {

	return (
		<SC.TextareaContainer>
			{label && <SC.Label>{label}</SC.Label>}
			<SC.Textarea className={className} {...restProps} ref={ref}/>
		</SC.TextareaContainer>
	);
});
