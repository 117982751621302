import {memo} from 'react';

import {
	PERSONAL_DATA_PROCESSING_POLICY, PUBLIC_CONTRACT_RUMIKI_INDIVIDUALS
} from '../../../../../shared/constants/path';
import {LinkWithLineBottom} from '../../../../links-navigate';
import * as SC from './rules-orders.style';

export const RulesOrders = memo(() => {

	return (
		<SC.Text>Оставляя заявку вы соглашаетесь с
			<LinkWithLineBottom
				label={'пользовательским соглашением'} path={PUBLIC_CONTRACT_RUMIKI_INDIVIDUALS} target={'_blank'}/>
			и <LinkWithLineBottom
				label={'политикой обработки персональных данных'} path={PERSONAL_DATA_PROCESSING_POLICY} target={'_blank'}/>
		</SC.Text>
	);
});
