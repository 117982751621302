import {FC, useCallback, useState} from 'react';

import {OrderServerStatus} from '../../../../interfaces/enums';
import {IOrder} from '../../../../interfaces/order';
import {renderStatus} from '../../../../shared/utils/order/order';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {ignoredStatuses} from '../../../filtering-table/components/order-card/order-card';
import {CancelOrder} from '../../../order/modals/components/cancel-order/cancel-order';
import * as SC from './order-card-btn-admin.style';

interface OrderCardAdminProps {
	order: IOrder;
	refetch?: () => void;
	handleActivateOrder: () => void;
}

export const OrderCardBtnAdmin: FC<OrderCardAdminProps> = ({order, refetch, handleActivateOrder}) => {
	const [showWindow, setShowWindow] = useState(false);

	const onClickShowWindowModal = useCallback(() => setShowWindow(prevState => !prevState), []);

	return (
		<>
			{order.status_info.status === OrderServerStatus.Paid
				? <SC.Closed>Заказ оплачен</SC.Closed>
				: <SC.BtnWrapper>
					{order.status_info.status === OrderServerStatus.WaitApproval &&
						<ButtonNew label={'Подтвердить заказ '} bgColor={'purple'} className={SC.ConfirmtOrderButton}
								   onClick={handleActivateOrder}/>}
					{order.status_info.status === OrderServerStatus.Success
						? <SC.Success>Заказ подтвержден</SC.Success>
						: (ignoredStatuses.includes(order.status_info.status)
							? <SC.Closed>Заказ {renderStatus(order).toLowerCase()}</SC.Closed> :
							<ButtonWithBorder label={'Отменить заказ'} onClick={onClickShowWindowModal}
											  borderColor={'yellow'}
											  className={SC.Button}/>)}
				</SC.BtnWrapper>}
			{showWindow &&
				<CancelOrder order={order} onClickClose={onClickShowWindowModal} refetch={refetch}/>}
		</>
	);
};
