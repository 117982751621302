import React, {FC, memo} from 'react';

import * as SC from './title.style';

interface Props {
	title: string;
}

export const Title:FC<Props> = memo(({title}) => {
	return (
		<SC.Title>{title}</SC.Title>
	);
});
