
export const convertPriceToString = (price: number): string => {
	if (price === 0) {
		return 'Бесплатно';
	}
	const rub = Math.floor(price / 100);
	const cop = price % 100;
	return `${rub}.${cop.toString().padStart(2, '0')} BYN`;
}; // 1000 => 10.00 BYN
export const getPriceLabel = (price: number, hasPriceChanges: boolean): string => {
	const formattedPrice = convertPriceToString(price);
	return hasPriceChanges ? `от ${formattedPrice}` : formattedPrice;
}; // 1000 => 10.00 BYN

export const transformPrice = (input: string): number => {
	if(!input){
		return 0;
	}

	if (!input.toString().includes(':')) {
		return parseInt(input) * 100;
	}
	// Преобразуем число в строку и убираем лишние нули
	const str = input.replace(/\.?0+$/, '');

	// Разделяем целую и дробную часть
	const [integerPart, decimalPart] = str.split(':');

	// Если дробная часть отсутствует, добавляем "00"
	if (!decimalPart) {
		return parseInt(integerPart) * 100;
	}
	// Если дробная часть есть, обрабатываем ее
	const formattedDecimal = decimalPart.padEnd(2, '0').slice(0, 2); // Убедимся, что дробная часть из 2 цифр
	return parseInt(integerPart) * 100 + parseInt(formattedDecimal);
};

export const formatNumber = (num: string) => {
	const length = num.length;

	// Проверяем, что число имеет более двух цифр
	if (length <= 2) {
		return `0.${num.padStart(2, '0')}`; // Если 1 или 2 цифры, добавляем 0 перед
	}

	return `${num.slice(0, length - 2)}.${num.slice(length - 2)}`;
};
