import styled from '@emotion/styled';
import {Link} from 'react-scroll';

export const Container = styled.section`
	display: flex;
	width: 100%;
	background-color: var(--background-Alternative);
	border-radius: 8px;
	justify-content: space-between;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		gap: 16px;
	}
`;
export const SliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;
	height: 500px;
	width: 40%;
	min-width: 386px;
	@media screen and (max-width: 800px) {
		min-width: 100%;
	}
`;
export const CheckedPhoto= styled.img`
	width: 100%;
	object-fit: cover;
	height: min(100%, 334px);
`;
export const Description = styled.p`
	overflow-y: auto;
	font-size: 14px;
	line-height: 1.385em;
	white-space: pre-wrap;
	width: 100%;
	padding-right: 16px;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9f9f9f;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-track {
		background-color: #eaeaea;
		border-radius: 20px;
	}
`;

export const TextContainer = styled.div`
	padding: 10px;
	display: flex;
	justify-content: space-between;
	width: 60%;
	@media screen and (max-width: 1040px) {
		flex-direction: column;
		gap: 16px;
	}
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;
export const PlatformContactsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 16px;
`;
export const PlatformCardTitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 1.385em;
`;
export const PlatformCardOrganizationLogo = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 50%;

	object-fit: cover;
	cursor: pointer;
`;
export const PlatformCardMainInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	min-width: 245px;
	margin-left: 28px;
	padding-top: 32px;
	gap: 15px;
`;
export const ToMapLink = styled.span`
	font-size: 16px;
	line-height: 1.3em;
	color: var(--green);
	text-decoration: underline;
	text-wrap: nowrap;
`;
export const PlatformCardAdditionalInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	height: max-content;
	margin-left: 14px;
	max-height: 450px;
	gap: 16px;
	@media screen and (max-width: 1040px) {
		gap: 0;
		overflow: auto;
		padding-right: 10px;
		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #9f9f9f;
			border-radius: 20px;
		}

		&::-webkit-scrollbar-track {
			background-color: #eaeaea;
			border-radius: 20px;
		}
	}
`;
export const ToMapLinkWrapper = styled(Link)`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	align-self: flex-end;
	gap: 6px;

	&:hover {
		cursor: pointer;
	}`;
