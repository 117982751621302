import styled from '@emotion/styled';

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Spacing-desctop-Body-M, 8px);
    align-self: stretch;
`;
export const AddBonuses = styled.p`
	color: var(--text-G, #1C8666);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
