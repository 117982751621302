import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

interface ActiveCard {
	active: boolean
}

export const PlatformCard = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: fit-content;
	margin-bottom: 20px;
	background-color: var(--background-Alternative);
	border-radius: 8px;
	@media screen and (max-width: 1360px) {
		display: flex;
		height: 100%;
		flex-direction: column;
		margin-bottom: 0;
	}
`;

export const PlatformCardLogoWrapper = styled.div`
	flex-shrink: 0;
	width: 683px;
	margin: -12px 0;
	overflow: hidden;
	border-radius: 8px;
	position: relative;
	border: 1px solid var(--gray-dark);
	transition: 0.7s;

	&:hover {
		img {
			transition: 0.7s ease-in-out;
			filter: brightness(0.6);
		}

		button {
			transition: 0.7s;
			display: block;
		}
	}

	@media screen and (max-width: 1360px) {
		width: 100%;
		margin: 0;
	}
`;
export const PlatformCardLogo = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 8px;
	max-height: 450px;
	object-fit: cover;
	object-position: center top;
	cursor: pointer;
	transition: 0.7s ease-in-out;

	@media screen and (max-width: 1360px) {
		border-radius: 8px;
		aspect-ratio: 16/9;
		object-fit: cover;
		cursor: pointer;
	}
`;
export const PlatformCardTitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 1.385em;
	margin-right: 90px;
	cursor: pointer;
`;
export const PlatformCardMainInfo = styled.div<ActiveCard>`
	display: flex;
	flex-direction: column;
	align-items: start;
	min-width: 245px;
	padding-top: 20px;
	gap: 15px;
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => !props.active ? 'none' : 'auto'};
	@media screen and (max-width: 1360px) {
		display: flex;
		flex-direction: column;
		align-items: start;
		min-width: 245px;
		padding-top: 16px;

		gap: 15px;
	}
`;
export const PlatformCardOrganizationLogo = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 50%;

	object-fit: cover;
	cursor: pointer;
`;
export const PlatformCardAdditionalInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 30px 10px 10px 0;
	position: relative;
	justify-content: space-between;
	@media screen and (max-width: 1360px) {
		overflow: hidden;
		margin-top: 5px;
		padding: 0;
		gap: 10px;
		justify-content: flex-end;
	}
`;
export const ToMapLinkWrapper = styled(Link)<ActiveCard>`
	display: flex;
	position: absolute;
	flex-shrink: 0;
	align-items: center;
	align-self: flex-end;
	cursor: pointer;
	gap: 6px;
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => !props.active ? 'none' : 'auto'};
`;
export const ToMapLink = styled.span`
	text-wrap: nowrap;
	font-size: 16px;
	line-height: 1.3em;
	color: var(--green);
	text-decoration: underline;
`;
export const PlatformCardInfoBlockDescription = styled.p<ActiveCard>`
	overflow-y: auto;
	font-size: 14px;
	line-height: 1.385em;
	height: 200px;
	white-space: pre-wrap;
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => !props.active ? 'none' : 'auto'};

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9f9f9f;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-track {
		background-color: #eaeaea;
		border-radius: 20px;
	}

	@media screen and (max-width: 1360px) {
		overflow-y: unset;
	}
`;
export const OrganizationCardModal = {
	bottom: '54px',
	left: '20px',
};
export const SpoilerWrapper = styled.div<ActiveCard>`
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => !props.active ? 'none' : 'auto'};
`;
export const ContentWrapper = styled.div`
	padding: 30px 10px 30px 30px;
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 424px;
	width: 100%;
	@media screen and (max-width: 1360px) {
		padding: 20px;
		max-height: 100%;
		height: 100%;
	}
	@media screen and (max-width: 400px) {
		padding: 10px;
	}
`;
export const ContactsDescriptionWrapper = styled.div`
	display: flex;
	gap: 6px;
	@media screen and (max-width: 1360px) {
		flex-direction: column;
		height: 100%;
	}
`;
