import styled from '@emotion/styled';

export const Input = styled.input`
	border-radius: 4px;
	border: 2px solid var(--border-Weak);
	padding: 12px 16px;
	color: var(--text-Strong);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	transition: 0.3s;
	width: 100%;
	outline: none;

	&:focus {
		border: 2px solid var(--border-B);
	}

	&::placeholder {
		color: var(--text-Weaker);
	}
`;
