import {FC, memo} from 'react';

import noImagePlaceholder from '../../../../../assets/img/no_image.png';
import {orgLogoSmall} from '../../../../../shared/utils/get-images-size';
import {UniqueImage} from '../../../../images/index.type';
import * as SC from './org-info.style';

interface Props {
	org_logo: UniqueImage[];
	org_name: string;
	address: string;
}

export const OrgInfo: FC<Props> = memo(({org_logo, org_name, address}) => {

	return (
		<SC.OrgInfoContainer>
			<SC.Logo src={orgLogoSmall(org_logo) || noImagePlaceholder} alt={'логотип'} loading={'lazy'}/>
			<SC.OrgInfoTextWrapper>
				<SC.OrgName>{org_name}</SC.OrgName>
				<SC.Address>{address}</SC.Address>
			</SC.OrgInfoTextWrapper>
		</SC.OrgInfoContainer>
	);
});
