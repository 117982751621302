import {FC, memo} from 'react';

import {LOGIN, REGISTER} from '../../../../../shared/constants/path';
import {Link, LinkWithBorder} from '../../../../links-navigate';
import * as SC from './benefit-register.style';

interface Props {
	clickLogin: ()=> void;
	handleGoToRegistration: ()=> void;
}

export const BenefitRegister:FC<Props> = memo(({handleGoToRegistration, clickLogin}) => {

	return (
		<SC.Container>
			<SC.BenefitBlockWrapper>
				<SC.TitleBenefit>Не упускайте выгоду! </SC.TitleBenefit>
				<SC.TextBenefit>Войдите в свой аккаунт или зарегистрируйтесь, чтобы оплачивать
					занятия бонусами и экономить.</SC.TextBenefit>
			</SC.BenefitBlockWrapper>
			<SC.BenefitBlockLinks>
				<Link label={'Войти'} path={LOGIN} click={clickLogin}/>
				<LinkWithBorder label={'Зарегестрироваться'} path={REGISTER}
								onClick={handleGoToRegistration}/>
			</SC.BenefitBlockLinks>
		</SC.Container>
	);
});
