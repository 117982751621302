import {FC} from 'react';

import status_icon from '../../../../../assets/order-icon/confirmed-icon.svg';
import {renderStatusOrder} from '../../../../../shared/utils/order/order';
import {converterTime, converterTimeString} from '../../../../../shared/utils/time/converter-time';
import {LabelDetail} from '../label-detail/label-detail';
import * as SC from './order-info.style';

interface Props {
	sports: string[];
	service_type: string;
	date: string;
	from: number;
	duration: number;
	status?: string;
	price: string;
}

export const OrderInfo: FC<Props> = ({date, from, service_type, sports, duration, status, price}) => {

	return (
		<SC.OrderInfo>
			<SC.Sports>{service_type}</SC.Sports>
			<SC.DetailWrapper>
				<LabelDetail label={'Вид спорта'}/>
				<SC.Sports>{sports.join(', ')}</SC.Sports>
			</SC.DetailWrapper>
			<SC.DetailWrapper>
				<LabelDetail label={'Дата и Время'}/>
				<SC.Date>{converterTimeString(date)} {converterTime(from)} - {converterTime(from + duration)}</SC.Date>
			</SC.DetailWrapper>
			{status && <SC.DetailWrapper>
				<LabelDetail label={'Статус'}/>
				<SC.StatusWrapper>
					<img src={status_icon} alt={'status icon'}/>
					<SC.Status>{renderStatusOrder(status)}</SC.Status>
				</SC.StatusWrapper>
			</SC.DetailWrapper>}
			<SC.Price>{price}</SC.Price>
		</SC.OrderInfo>
	);
};
