import styled from '@emotion/styled';

export const BonusesForOrder = styled.p`
	padding: 8px 16px;
	background-color: var(--background-Green-weak);
	border-radius: 4px;
	color: var(--text-Weak);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
