import styled from '@emotion/styled';

export const OrderDetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-S, 4px);
	position: relative;
`;
export const TypeDetail = styled.span`
	color: var(--text-Weak);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	text-wrap: nowrap;
	width: 160px;
`;
