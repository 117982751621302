import React, {FC, memo} from 'react';

import * as SC from './add-bonuses.style';

interface Props {
	text: string;
}

export const AddBonuses:FC<Props> = memo(({text}) => {
	return (
		<SC.BonusesForOrder>{text}</SC.BonusesForOrder>
	);
});
