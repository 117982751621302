import  {FC, memo} from 'react';

import bonuses_icon from '../../../../assets/icon/bonuses-order.svg';
import {ServiceListItem, ServiceSingle} from '../../../../interfaces/service';
import {LOGIN, REGISTER} from '../../../../shared/constants/path';
import {ButtonNew} from '../../../buttons-new';
import {Link, LinkWithBorder} from '../../../links-navigate';
import {ModalNew} from '../../../modal-new/modal-new';
import {BonusesIconText} from '../components/bonuses-icon-text/bonuses-icon-text';
import {ForPayment} from '../components/for-payment/for-payment';
import {OrderDetail} from '../components/order-detail/order-detail';
import {OrgInfo} from '../components/org-info/org-info';
import {TitleOrderFast} from '../components/title-order-fast/title-order-fast';
import * as SC from './fast-order-success.style';

interface Props {
	service: ServiceListItem | ServiceSingle;
	onClickClose: () => void;
	goToRegistration: () => void;
	goToLogin: () => void;
	time: string;
	price: string;
	payment_reward: number;
	order_reward: number;
}

export const FastOrderSuccess: FC<Props> = memo(({
													 service, onClickClose, time, price, goToRegistration, goToLogin,
													 payment_reward, order_reward,
												 }) => {

	return (
		<ModalNew onClickClose={onClickClose} classNameWrapper={SC.Modal}>
			<TitleOrderFast order_reward={order_reward}/>
			<SC.OrderInfoContainer>
				<OrgInfo org_logo={service.org_info.logo} org_name={service.org_info.org_name}
						 address={service.platform_info.location_info.address}/>
				<SC.ServiceType>{service.service_type}</SC.ServiceType>
				<SC.DetailWrapper>
					<OrderDetail detailLabel={'Вид спорта'}
								 children={<SC.ServiceType>{service.sports.join(', ')}</SC.ServiceType>}/>
					<OrderDetail detailLabel={'Дата и Время'} children={<SC.DetailDate>{time}</SC.DetailDate>}/>
				</SC.DetailWrapper>
				{payment_reward > 0 && <SC.BonusesIconWrapper>
					<img src={bonuses_icon} alt={'bonuses icon'}/>
					<BonusesIconText bonuses={payment_reward}/>
				</SC.BonusesIconWrapper>}
			</SC.OrderInfoContainer>
			<SC.Price>{price}</SC.Price>
			<SC.PaymentWrapper>
				<ForPayment/>
				<ButtonNew label={'Оплатить'}/>
				<SC.RecommendationPaymentLater>Вы сможете оплатить заказ позже в разделе "Мои заказы" вашего личного
					кабинета.</SC.RecommendationPaymentLater>
			</SC.PaymentWrapper>
			<SC.BenefitWrapper>
				<SC.BenefitTitleWrapper>
					<SC.BenefitTitle>Не упускайте выгоду! </SC.BenefitTitle>
					<SC.BenefitRecommendation>Войдите в свой аккаунт или зарегистрируйтесь,
						чтобы оплачивать услуги бонусами и экономить.</SC.BenefitRecommendation>
				</SC.BenefitTitleWrapper>
				<SC.BenefitWrapperLinks>
					<LinkWithBorder label={'Зарегестрироваться'} path={REGISTER} onClick={goToRegistration}/>
					<Link label={'Войти'} path={LOGIN} click={goToLogin}/>
				</SC.BenefitWrapperLinks>
			</SC.BenefitWrapper>
		</ModalNew>
	);
});
