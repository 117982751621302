import {css} from '@emotion/css';
import styled from '@emotion/styled';

interface DescriptionStyle {
	isExpanded: boolean
}

export const DescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: flex-start;
`;
export const Description = styled.p<DescriptionStyle>`
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: var(--text-Strong);
    max-height: ${({isExpanded}) => (isExpanded ? 'auto' : '3.6em')};
    overflow: hidden;
    transition: height 0.3s ease;
    white-space: pre-wrap;
`;
export const ShowDescription = styled.button`
	display: flex;
	gap: 10px;
	background-color: transparent;
	color: var(--green);
	font-weight: 400;
	font-size: 16px;
	line-height: 1.385;
	text-align: center;
	width: fit-content;
	cursor: pointer;
`;
export const ShowDescriptionIcon = styled.img<DescriptionStyle>`
	rotate: ${({isExpanded}) => (isExpanded ? '180deg' : 0)};
	filter: invert(35%) sepia(83%) saturate(399%) hue-rotate(144deg) brightness(64%) contrast(89%);
`;
export const Label = styled.span`
	font-weight: 300;
	font-size: 14px;
	line-height: 1.3;
`;
export const DataContainer = styled.div`
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
	gap: 20px;

`;
export const HasAbonement = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
`;
export const Window = css`
	max-width: 760px !important;
`;
export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const ServiceCardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 20px;
`;
export const Hr = styled.hr`
	width: 100%;
	background-color: #cfcfcf;
`;
export const TextHoverWrapper = styled.div`
	position: relative;
	cursor: pointer;
`;

export const Price = styled.span`
    font-family: Jost, sans-serif;
    color: var(--text-Strong);
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    text-align: center;
`;
