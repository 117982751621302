import classNames from 'classnames';
import {FC, useCallback} from 'react';
import {NavLink} from 'react-router-dom';

import IconClose from '../../../../../components/svg-icon-components/icon-close';
import {AuthPages} from '../../../../../interfaces/interfaces';
import {useHandleGoToLogin, useHandleGoToRegistration} from '../../../../../pages/orders/utils';
import {
	resetInfoOrdersOrg,
	resetInfoOrdersPlatform,
	resetInfoOrdersUser
} from '../../../../../redux/reducers/orders-reducer';
import {resetDataUser, setResetIsAuth} from '../../../../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {isAuthSelector} from '../../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {LOCAL_STORAGE_KEY_MAP} from '../../../../../shared/constants/local-storage';
import {
	ABONEMENT, ABOUT_PROJECT, ADMIN_SETTING_PLATFORMS_DEFAULT, ALL_ORG, LOGIN, MAIN,
	ORDER, PLAY_FIELDS, PROFILE_USER, REGISTER
} from '../../../../../shared/constants/path';
import {SERVICES_NAV_LINK_CONFIG} from '../../../../../shared/constants/service/nav';
import {isRightTokenTime} from '../../../../../shared/utils/decoder-jwt';
import {getLocalStorage, isAuth} from '../../../../../shared/utils/save-local-storage';
import styles from './burger-menu.module.css';

interface BurgerMenuProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({isOpen, setIsOpen}) => {
	const isUserAuthenticatedByStore = useAppSelector(isAuthSelector);
	const isUserAuthenticated = isUserAuthenticatedByStore || isAuth();
	const adminLink = getLocalStorage(LOCAL_STORAGE_KEY_MAP.hasAdminRole) ? ADMIN_SETTING_PLATFORMS_DEFAULT : undefined;
	const dispatch = useAppDispatch();
	const handleGoToPage = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleGoToLogout = useCallback(() => {
		localStorage.clear();
		dispatch(resetInfoOrdersUser());
		dispatch(resetDataUser());
		dispatch(setResetIsAuth());
		dispatch(resetInfoOrdersPlatform());
		dispatch(resetInfoOrdersOrg());
		handleGoToPage();
	}, []);

	const handleGoToRegistration = useCallback(() => {
		useHandleGoToRegistration(dispatch);
		handleGoToPage();
	}, []);

	const handleGoToLogin = useCallback(() => {
		useHandleGoToLogin(dispatch);
		handleGoToPage();
	}, []);

	const isNotTokenTimeHandleGoToLogin = () => {
		handleGoToLogout();
		handleGoToLogin();
		handleGoToPage();
	};
	const isNotTokenTimeHandleGoToRegistration = () => {
		handleGoToLogout();
		handleGoToRegistration();
		handleGoToPage();
	};

	return (
		<div
			className={classNames(styles.burger_menu_overlay, {
				[styles.burger_menu_opened]: isOpen,
			})}
			onClick={handleGoToPage}
		>
			<div className={styles.burger_menu} onClick={(event) => event.stopPropagation()}>
				<IconClose
					width={14}
					height={14}
					className={styles.burger_menu_close_button}
					onClick={handleGoToPage}
				/>
				<div className={styles.burger_menu_block}>
					<h3 className={styles.burger_menu_block_title}>Основные страницы</h3>
					<ul className={styles.burger_menu_block_list}>
						<li>
							<NavLink to={MAIN} className={styles.burger_menu_block_list_item} onClick={handleGoToPage}>
								Главная
							</NavLink>
						</li>
						<li>
							<NavLink
								to={ALL_ORG}
								className={styles.burger_menu_block_list_item}
								onClick={handleGoToPage}
							>
								Спортклубы
							</NavLink>
						</li>
						<li>
							<NavLink
								to={PLAY_FIELDS}
								className={styles.burger_menu_block_list_item}
								onClick={handleGoToPage}
							>
								Залы
							</NavLink>
						</li>
						<li>
							<NavLink to={ORDER} className={styles.burger_menu_block_list_item} onClick={handleGoToPage}>
								Услуги
							</NavLink>
						</li>
						<li>
							<NavLink to={ABONEMENT} className={styles.burger_menu_block_list_item}
									 onClick={handleGoToPage}>
								Абонементы
							</NavLink>
						</li>
						<li>
							<NavLink
								to={ABOUT_PROJECT}
								className={styles.burger_menu_block_list_item}
								onClick={handleGoToPage}
							>
								О проекте
							</NavLink>
						</li>
						{SERVICES_NAV_LINK_CONFIG.map(({value, label}) => (
							<li key={value}>
								<NavLink
									to={value}
									className={styles.burger_menu_block_list_item}
									onClick={handleGoToPage}
								>
									{label}{' '}
								</NavLink>
							</li>
						))}
						{/* <li>*/}
						{/*	<NavLink*/}
						{/*		to={FAQ}*/}
						{/*		className={styles.burger_menu_block_list_item}*/}
						{/*		onClick={handleGoToPage}*/}
						{/*	>*/}
						{/*		FAQ*/}
						{/*	</NavLink>*/}
						{/* </li>*/}
					</ul>
				</div>
				<div className={styles.burger_menu_block}>
					<h3 className={styles.burger_menu_block_title}>Аккаунт</h3>
					<ul className={styles.burger_menu_block_list}>
						{isUserAuthenticated && isRightTokenTime() ? (
							<>
								<li>
									<NavLink
										to={PROFILE_USER}
										className={styles.burger_menu_block_list_item}
										onClick={handleGoToPage}
									>
										Личный кабинет
									</NavLink>
								</li>
								<li>
									{adminLink && <NavLink
										to={adminLink}
										className={styles.burger_menu_block_list_item}
										onClick={handleGoToPage}
									>
										Рабочий кабинет
									</NavLink>}
								</li>
								<li>
									<NavLink
										to={PROFILE_USER}
										className={styles.burger_menu_block_list_item}
										onClick={handleGoToPage}
									>
										История заказов
									</NavLink>
								</li>
								<li>
									<NavLink
										to={MAIN}
										className={styles.burger_menu_block_list_item}
										onClick={handleGoToLogout}
									>
										Выйти
									</NavLink>
								</li>
							</>
						) : (
							<>
								<li>
									<NavLink
										state={{page: AuthPages.registration}}
										to={REGISTER}
										className={styles.burger_menu_block_list_item}
										onClick={isRightTokenTime() ? handleGoToRegistration : isNotTokenTimeHandleGoToRegistration}
									>
										Регистрация
									</NavLink>
								</li>
								<li>
									<NavLink
										state={{page: AuthPages.login}}
										to={LOGIN}
										className={styles.burger_menu_block_list_item}
										onClick={isRightTokenTime() ? handleGoToLogin : isNotTokenTimeHandleGoToLogin}
									>
										Вход
									</NavLink>
								</li>
							</>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};
