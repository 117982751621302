import {FC, memo} from 'react';

import {getBonusesWordIcon} from '../../modal-anonim-payment/modal-anonim-payment';
import * as SC from './bonuses-icon-text.style';

interface Props {
	bonuses: number;
}

export const BonusesIconText:FC<Props> = memo(({bonuses}) => {
	return (
		<SC.BonusesIconText>Начислим <br/> {getBonusesWordIcon(bonuses / 100)} <br/> за
			оплату</SC.BonusesIconText>
	);
});
