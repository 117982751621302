import styled from '@emotion/styled';

export const BonusesWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    width: 100%;
`;
export const ResultPrice = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Body-M, 8px);
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	align-items: center;
`;
export const Price = styled.span`
	color: var(--text-G, #1C8666);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const HasBalance = styled.span`
    color: var(--text-Weak, #4D4D4D);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const ButtonPaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;
