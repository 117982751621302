import {FC, memo, useCallback, useState} from 'react';

import bonuses_icon from '../../../../../../assets/icon/bonuses-order.svg';
import {AbonementType} from '../../../../../../interfaces/abonement';
import {ServiceListItem} from '../../../../../../interfaces/service';
import {convertPriceToString} from '../../../../../../shared/utils/converter-price';
import {ButtonNew} from '../../../../../buttons-new';
import {ButtonCheckbox} from '../../../../../checkboxes/button-checkbox/button-checkbox';
import {BonusesIconText} from '../../../components/bonuses-icon-text/bonuses-icon-text';
import {ForPayment} from '../../../components/for-payment/for-payment';
import {bonusesAvailable, calculateFinishPrice, getLimitAbonement, workingAbonementTo} from '../../../utils';
import {CardAbonement} from '../card-abonement/card-abonement';
import {ServicesAbonementAdaptive} from '../services-abonement-adaptive/services-abonement-adaptive';
import * as SC from './details-abonement-adaptive.style';

interface Props {
	abonement: AbonementType;
	balance: number;
	payment_reward: number;
	useAllBonuses: boolean;
	onClickBye: () => void;
	clickUseAllBonuses: () => void;
	services: ServiceListItem[]
}

export const DetailsAbonementAdaptive: FC<Props> = memo(({
															 abonement, balance, payment_reward, onClickBye,
															 clickUseAllBonuses, useAllBonuses, services,
														 }) => {
	const [showMore, setShowMore] = useState(false);
	const clickShowMore = useCallback(() => setShowMore(prevState => !prevState), []);
	const info = abonement.info;

	return (
		<>
			<CardAbonement abonement={abonement} showMore={false} clickShowMore={clickShowMore}/>
			{showMore && <>
				<ServicesAbonementAdaptive abonement={abonement} services={services}/>
				<SC.AbonementInfoWrapper>
					{info.description.length > 0 &&
						<SC.Description>{info.description}</SC.Description>}
					<SC.LimitWrapper>
						<SC.Limit>{getLimitAbonement(info.duration)}</SC.Limit>
						<SC.WorkTo>До {workingAbonementTo(info.duration)}</SC.WorkTo>
					</SC.LimitWrapper>
					<SC.Price>{convertPriceToString(info.price)}</SC.Price>
				</SC.AbonementInfoWrapper>
			</>}
			<SC.BonusesWrapper>
				<SC.HasBonuses>Доступно <span>{bonusesAvailable({
					useAllBonuses, balance, price: info.price
				})}</span></SC.HasBonuses>
				<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'} name={'bonuses'}
								value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
				<SC.ResultPrice>
					<span>Итого</span>
					<SC.PriceRes>{calculateFinishPrice({price: info.price, balance, useAllBonuses})}</SC.PriceRes>
				</SC.ResultPrice>
				<SC.IconBonusesWrapper>
					<img src={bonuses_icon} alt="bonuses icon"/>
					<BonusesIconText bonuses={payment_reward}/>
				</SC.IconBonusesWrapper>
			</SC.BonusesWrapper>
			<SC.ButtonPaymentWrapper>
				<ForPayment/>
				<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickBye}/>
			</SC.ButtonPaymentWrapper>
		</>
	);
});
