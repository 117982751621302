import {setIsShowRegOrAuth, setStepRegistration1} from '../../redux/reducers/user-data-reducer';
import {AppDispatch} from '../../redux/store';

export const useHandleGoToRegistration = (dispatch: AppDispatch) => {
	localStorage.clear();
	sessionStorage.setItem('old_url', location.pathname + location.search);
	dispatch(setStepRegistration1());
	dispatch(setIsShowRegOrAuth({type: false}));
};


export const useHandleGoToLogin = (dispatch: AppDispatch) => {
	localStorage.clear();
	sessionStorage.setItem('old_url', location.pathname + location.search);
	dispatch(setStepRegistration1());
	dispatch(setIsShowRegOrAuth({type: true}));
};
