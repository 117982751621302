import {FC, memo, ReactNode} from 'react';

import {getBonusesWord} from '../../modal-anonim-payment/modal-anonim-payment';
import {Title} from '../title/title';
import * as SC from './title-order-fast.style';

interface Props {
	children?: ReactNode;
	order_reward: number;
}

export const TitleOrderFast:FC<Props> = memo(({order_reward}) => {

	return (
		<SC.TitleWrapper>
			<Title title={'Услуга забронирована'}/>
			{order_reward > 0 && <SC.AddBonuses>{getBonusesWord(order_reward / 100)}</SC.AddBonuses>}
		</SC.TitleWrapper>
	);
});
