import styled from '@emotion/styled';

export const OrgInfoContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;
export const Logo = styled.img`
	width: 58px;
	height: 58px;
	border-radius: 50%;
`;
export const OrgInfoTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
export const OrgName = styled.h4`
	color: var(--text-Strong);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Address = styled.p`
	color: var(--text-Strong);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
`;
