import {createAsyncThunk} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {OrderAttachDelete} from '../../../interfaces/order';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {deleteOrderAttach, setShowLoader} from '../../reducers/user-data-reducer';

export const fetchDeleteOrderAttach = createAsyncThunk(
  'fieldSlice/userDataSlice',
  async (data: OrderAttachDelete, {dispatch, rejectWithValue}) => {
    try {
      dispatch(setShowLoader({isShowLoader: true}));
      const response = await crmPlatformAPI.removeOrderAttach(data);
      dispatch(setShowLoader({isShowLoader: false}));
      if (response.status === 200) {
        dispatch(deleteOrderAttach(data.inventory));
        return {data: response.data.attached};
      }
    } catch (error) {
      if (isAxiosError(error)) {
        handleAxiosError({dispatch, rejectWithValue, error});
      }
    }
  },
);
