import {FC, memo} from 'react';

import * as SC from './button-checkbox.style';

interface Props {
	label: string;
	checked: boolean;
	value?: string
	handleCheckboxChange?: ()=>void
	name?: string;
	id: string
}

export const ButtonCheckbox:FC<Props> = memo(({label, checked, handleCheckboxChange, value, name, id}) => {

	return (
		<SC.Container>
			<SC.Label>{label}</SC.Label>
			<SC.Switch htmlFor={id}>
				<input
					type="checkbox"
					id={id}
					name={name}
					value={value}
					checked={checked}
					onChange={handleCheckboxChange}
				/>
				<div className="slider round"></div>
			</SC.Switch>
		</SC.Container>
	);
});
