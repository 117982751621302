import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UserRole} from '../../interfaces/enums';
import {
  IComments,
  JwtData,
  MessageUser,
  OrderAttachService,
  UserMeServerInfo,
  UserWithPlatformInfo,
} from '../../interfaces/interfaces';
import {IOrder} from '../../interfaces/order';
import {OrganizationListItem} from '../../interfaces/organization';
import {PlatformListItem} from '../../interfaces/platform';
import {filterAdminRoles, TFullUserRole} from '../../interfaces/role';
import {ServiceListItem} from '../../interfaces/service';
import {LOCAL_STORAGE_KEY_MAP} from '../../shared/constants/local-storage';
import {
  emptyMessage,
  messageAuthError,
  messageBack,
  messageErrDataRecover,
  messageErrDataUserByLogin,
  messageErrNotDataRecover,
  messageErrorData,
  messageErrorServer,
  messageErrorUpdateUser,
  messageErrRecoverCode,
  messageErrStep1,
  messageRepeat,
  messageSuccessUpdatePlatform,
} from '../../shared/constants/message-user';
import {getIdUser} from '../../shared/utils/decoder-jwt';
import {isAdminRole, isModeratorRole, isOwnerRole} from '../../shared/utils/roles';
import {saveLocalStorage} from '../../shared/utils/save-local-storage';
import {fetchAuthorizeUser} from '../thunks/authorize-thunk';
import {fetchGetAvatars} from '../thunks/get-avatars-thunk';
import {fetchGetAllUsersBlackList} from '../thunks/get-black-list-by-platform';
import {fetchGetHistoryOrdersByServiceUser} from '../thunks/history/get-history-orders-by-servise-thunk';
import {fetchGetVisitedOrganizations} from '../thunks/history/get-history-organizations-thunk';
import {fetchGetTotalVisitedOrganizations} from '../thunks/history/get-history-organizations-total-thunk';
import {fetchGetVisitedPlatforms} from '../thunks/history/get-history-platforms-thunk';
import {fetchGetTotalVisitedPlatforms} from '../thunks/history/get-history-platforms-total-thunk';
import {fetchGetOrderAttach} from '../thunks/order/get-order-attach-thunk';
import {fetchRecoverPasswordComplete} from '../thunks/recover-password-complete-thunk';
import {fetchRecoverPassword} from '../thunks/recover-password-thunk';
import {fetchRegistrationUser} from '../thunks/registration-thunk';
import {fetchGetVisitedServices} from '../thunks/services/get-history-services-thunk';
import {fetchGetTotalVisitedServices} from '../thunks/services/get-history-services-total-thunk';
import {fetchUpdatePassword} from '../thunks/update-password-thunk';
import {fetchGetAllUsers} from '../thunks/user/get-all-users-thunk';
import {fetchGetUserById} from '../thunks/user/get-user-by-id';
import {fetchGetUserClosestOrder} from '../thunks/user/get-user-closest-order-thunk';
import {fetchGetUserFullRoles} from '../thunks/user/get-user-fullRoles-thunk';
import {fetchGetUserRoles} from '../thunks/user/get-user-roles-thunk';
import {fetchUpdateDataUser} from '../thunks/user/update-user-data-thunk';

export interface UserDataState {
  stepRegistration1: boolean;
  stepRegistration2: boolean;
  isShowAuth: boolean;
  isShowMessage: boolean;
  isAuth: boolean;
  isLoginPage: boolean;
  isErrorAuth: boolean;
  isShowLoader: boolean;
  nickName: string;
  userMeta: {
    avatar: string;
    subscribed_to_news: boolean;
  };
  userData: {
    id?: string;
    name: string;
    password?: string;
    phone: string;
    email: string;
    balance: number;
  };
  jwt: string;
  // TODO: здесь лежат роли, возьми их плиз отсюда
  jwtData: JwtData;
  selectedAdminFullRole?: TFullUserRole;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  messageUser: MessageUser;
  allUsers: UserMeServerInfo[];
  totalCount: number,
  allBlackUsers: UserWithPlatformInfo[];
  allBlackTotalCount: number;
  commentaries: IComments[];
  checkedUserFullRoles?: TFullUserRole[];
  viewedUserFullRoles?: TFullUserRole[];

  allPlatformsVisitedUser: PlatformListItem[];
  allPlatformsVisitedUserTotal: number;

  allOrganizationsVisitedUser: OrganizationListItem[];
  allOrganizationsVisitedUserTotal: number;

  allServicesVisitedUser: ServiceListItem[];
  allServicesVisitedUserTotal: number;
  orderAttach: OrderAttachService[]
  closestOrderUser: IOrder | null;
  order: IOrder | null;
  historyOrders: IOrder[];
  historyByServiceOrders: IOrder[];
  historyOrdersTotal: number;

  loginCheckedUserForAddRole: string;
  isNetwork: boolean;
  isUserCheckedService: boolean;
  city: string | null;
  avatars: string[];
  openForUrl: string;
}

const initialState: UserDataState = {
  // Better to create enum with steps
  stepRegistration1: true,
  stepRegistration2: false,
  // Better to separate isSomething state, need review
  isShowAuth: false,
  openForUrl: '',
  isShowMessage: false,
  isAuth: false,
  isLoginPage: false,
  isErrorAuth: false,
  isShowLoader: false,
  nickName: '',
  userMeta: {
    avatar: '',
    subscribed_to_news: false,
  },
  userData: {
    id: '',
    name: '',
    password: '',
    phone: '',
    email: '',
    balance: 0,
  },
  jwt: '',
  jwtData: {
    exp: 0,
    res: {},
    roles: [UserRole.viewer],
    sub: '',
  },
  selectedAdminFullRole: undefined,
  status: 'idle',
  messageUser: {
    titleMessage: '',
    textMessage: '',
    descriptionMessage: '',
    action: '',
    btnTitle: '',
    isErrorMessage: false,
  },
  allUsers: [],
  totalCount: 0,
  allBlackUsers: [],
  allBlackTotalCount: 0,
  commentaries: [],

  orderAttach: [],
  allPlatformsVisitedUser: [],
  allPlatformsVisitedUserTotal: 0,
  allOrganizationsVisitedUser: [],
  allOrganizationsVisitedUserTotal: 0,
  allServicesVisitedUser: [],
  allServicesVisitedUserTotal: 0,
  closestOrderUser: null,
  order: null,
  historyOrders: [],
  historyByServiceOrders: [],
  historyOrdersTotal: 0,

  loginCheckedUserForAddRole: '',
  isNetwork: true,
  isUserCheckedService: false,
  city: null,
  avatars: [],
};

export const userDataSlice = createSlice({
  name: 'userDataSlice',
  initialState,
  reducers: {
    setIsUserCheckedService: (state: UserDataState, action) => {
      state.isUserCheckedService = action.payload.data;
    },
    resetDataUser: (state: UserDataState) => {
      state.stepRegistration1 = true;
      state.stepRegistration2 = false;
      state.allPlatformsVisitedUser = [];
      state.allPlatformsVisitedUserTotal = 0;
      state.allOrganizationsVisitedUser = [];
      state.allOrganizationsVisitedUserTotal = 0;
      state.allServicesVisitedUser = [];
      state.allServicesVisitedUserTotal = 0;
      state.closestOrderUser = null;
      state.historyOrders = [];
      state.historyByServiceOrders = [];
      state.historyOrdersTotal = 0;
      state.selectedAdminFullRole = undefined;
      state.nickName = '';
      state.userData = {
        id: '',
        name: '',
        password: '',
        phone: '',
        email: '',
        balance: 0,
      };
      state.allUsers = [];
      state.totalCount = 0;
      state.isAuth = false;
      state.loginCheckedUserForAddRole = '';
      state.allBlackUsers = [];
      state.allBlackTotalCount = 0;
    },
    setIsNetwork: (state: UserDataState, action) => {
      state.isNetwork = action.payload.data;
    },
    setBlackList: (state: UserDataState, action) => {
      state.allBlackUsers = [...action.payload.data];
    },
    resetBlackList: (state: UserDataState) => {
      state.allBlackUsers = [];
    },
    setLoginCheckedUserForAddRole: (state: UserDataState, action) => {
      state.loginCheckedUserForAddRole = action.payload.login;
    },
    setStepRegistration1: (state: UserDataState) => {
      state.stepRegistration1 = true;
      state.stepRegistration2 = false;
    },
    setStepRegistration2: (state: UserDataState) => {
      state.stepRegistration2 = true;
      state.stepRegistration1 = false;
    },
    setShowLoader: (state: UserDataState, action) => {
      state.isShowLoader = action.payload.isShowLoader;
    },
    setUserData: (state: UserDataState, action) => {
      state.userData = action.payload.userData;
    },
	setOpenForUrl: (state, action)=>{
	  state.openForUrl = action.payload;
	},
    setMessageUser: (state: UserDataState, action: PayloadAction<{
      messageUser: MessageUser,
      textMessage?: string
      titleMessage?: string
      descriptionMessage?: string
    }>) => {
      state.messageUser.titleMessage = action.payload.titleMessage || action?.payload?.messageUser?.titleMessage;
      state.messageUser.textMessage = action?.payload?.textMessage || action?.payload?.messageUser?.textMessage;
      state.messageUser.descriptionMessage = action.payload.descriptionMessage;
      state.messageUser.action = action?.payload?.messageUser?.action;
      state.messageUser.btnTitle = action?.payload?.messageUser?.btnTitle || 'Ok';
      state.messageUser.isErrorMessage = action?.payload?.messageUser?.isErrorMessage;
    },
    setResetMessageUser: (state: UserDataState) => {
      state.messageUser = emptyMessage;
    },
    setIsShowAuth: (state: UserDataState) => {
      state.isShowAuth = !state.isShowAuth;
    },
    setIsShowRegOrAuth: (state: UserDataState, action) => {
      state.isShowAuth = action.payload.type;
    },
    setIsShowMessage: (state: UserDataState, action) => {
      state.isShowMessage = action.payload.isShowMessage;
    },
    setResetIsAuth: (state: UserDataState) => {
      state.isAuth = false;
      state.jwt = '';
      state.jwtData = {
        exp: 0,
        res: {},
        roles: [UserRole.viewer],
        sub: '',
      };
    },
    setNotErrorAuth: (state: UserDataState) => {
      state.isErrorAuth = false;
    },
    setIsAuthUser: (state: UserDataState) => {
      state.isAuth = true;
    },
    setIsLoginPage: (state: UserDataState, action) => {
      state.isLoginPage = action.payload;
    },
    setUserCity: (state: UserDataState, action) => {
      state.city = action.payload;
    },
    setSelectedAdminFullRole: (state: UserDataState, action) => {
      state.selectedAdminFullRole = action.payload;
    },
    setHistoryOrders: (state: UserDataState, action) => {
      state.historyOrders = action.payload.data;
    },
    setHistoryOrdersTotal: (state: UserDataState, action) => {
      state.historyOrdersTotal = action.payload.data;
    },
    setUserComments: (state: UserDataState, {payload}: PayloadAction<IComments[]>) => {
      state.commentaries = payload;
    },
    clearCommentaries: (state: UserDataState) => {
      state.commentaries = [];
    },
    setOrder: (state: UserDataState, action) => {
      state.order = action.payload;
    },
    resetOrderAttach: (state: UserDataState) => {
      state.orderAttach = [];
    },
    deleteOrderAttach: (state: UserDataState, action) => {
      state.orderAttach.filter(i=>{
        return i.service_info.service_id !== action.payload;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRecoverPasswordComplete.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchRecoverPasswordComplete.fulfilled, (state: UserDataState, {payload}) => {
      state.isShowLoader = false;
      state.status = 'succeeded';
      if (payload?.status === 200) {
        saveLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt, payload.data);
      }
      if (payload?.status === 204) {
        state.messageUser = messageErrRecoverCode;
        state.status = 'failed';
        state.isShowMessage = true;
      }
    });
    builder.addCase(fetchRecoverPasswordComplete.rejected, (state: UserDataState) => {
      state.messageUser = messageErrDataRecover;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchRecoverPassword.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchRecoverPassword.fulfilled, (state: UserDataState, {payload}) => {
      state.isShowLoader = false;
      state.status = 'succeeded';

      if (payload?.status === 200) {
        return;
      }

      if (payload?.status === 204) {
        state.messageUser = messageErrNotDataRecover;
        state.isShowMessage = true;
      }
    });
    builder.addCase(fetchRecoverPassword.rejected, (state: UserDataState) => {
      state.messageUser = messageErrDataRecover;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetUserById.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetUserById.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        if (payload?.data?.description) {
          state.userData = payload?.data?.description?.Registered;
          state.nickName = payload?.data?.description?.Registered.name;
          saveLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick, payload?.data?.description?.Registered.name);
        }
        if (payload?.data?.meta) {
          state.userMeta.avatar = payload?.data?.meta?.avatar;
          state.userMeta.subscribed_to_news = payload?.data?.meta?.subscribed_to_news;
        }

        state.userData.balance = payload?.data?.balance;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetUserById.rejected, (state: UserDataState, action) => {
      if (action.payload === 500) {
        state.messageUser = messageBack;
      } else {
        state.messageUser = messageErrDataUserByLogin;
      }
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });
    builder.addCase(fetchRegistrationUser.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.messageUser = emptyMessage;
    });
    builder.addCase(fetchRegistrationUser.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        saveLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick, payload.dataUser);
      }
      if (payload?.status === 204) {
        state.isShowLoader = false;
        state.messageUser = messageErrStep1;
        state.isShowMessage = true;
      }
    });
    builder.addCase(fetchRegistrationUser.rejected, (state: UserDataState, action) => {
      if (action.payload === 500) {
        state.messageUser = messageBack;
      } else {
        state.messageUser = messageRepeat;
      }
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });
    builder.addCase(fetchAuthorizeUser.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.isErrorAuth = false;
    });
    builder.addCase(fetchAuthorizeUser.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.jwt = payload.jwt;
        if (payload.jwtData) {
          state.jwtData = payload.jwtData;
          const hasAdminRole = payload.jwtData?.roles?.find((role) => isAdminRole(role) || isModeratorRole(role) || isOwnerRole(role)) !== undefined;
          saveLocalStorage(LOCAL_STORAGE_KEY_MAP.hasAdminRole, hasAdminRole);
        }
        state.status = 'succeeded';
        state.isAuth = true;
        saveLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt, payload.jwt);
        saveLocalStorage(LOCAL_STORAGE_KEY_MAP.auth, true);
      }
      if (payload?.status === 204) {
        state.messageUser = messageAuthError;
        state.status = 'failed';
        state.isShowMessage = true;
        state.isErrorAuth = true;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchAuthorizeUser.rejected, (state: UserDataState, action) => {
      if (action.payload === 500) {
        state.messageUser = messageAuthError;
      }
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
      state.isErrorAuth = true;
    });
    builder.addCase(fetchGetAllUsers.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetAllUsers.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.allUsers = [...payload.data];
        state.totalCount = payload.totalCount;
        state.isShowLoader = false;
        state.status = 'succeeded';
      }
    });
    builder.addCase(fetchGetAllUsers.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorData;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetUserFullRoles.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.messageUser = emptyMessage;
    });
    builder.addCase(fetchGetUserFullRoles.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.isShowLoader = false;
        if (state.jwtData.sub === payload.data.sub || getIdUser() === payload.data.sub) {
          const filteredRoles = filterAdminRoles(payload.data.roles);
          state.checkedUserFullRoles = filteredRoles;
          state.selectedAdminFullRole = filteredRoles.at(0);
          saveLocalStorage(LOCAL_STORAGE_KEY_MAP.selectedFullRole, filteredRoles.at(0));
        }
        state.viewedUserFullRoles = payload.data.roles;
        state.status = 'succeeded';
      }
    });
    builder.addCase(fetchGetUserFullRoles.rejected, (state: UserDataState) => {
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetUserRoles.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.messageUser = emptyMessage;
    });
    builder.addCase(fetchGetUserRoles.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.isShowLoader = false;
        // state.checkedUserRoles = [...payload.data.roles];
        state.status = 'succeeded';
      }
    });
    builder.addCase(fetchGetUserRoles.rejected, (state: UserDataState) => {
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchUpdateDataUser.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.messageUser = emptyMessage;
    });
    builder.addCase(fetchUpdateDataUser.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.messageUser = messageSuccessUpdatePlatform;
        state.isShowMessage = true;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchUpdateDataUser.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorUpdateUser;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchUpdatePassword.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
      state.messageUser = emptyMessage;
    });
    builder.addCase(fetchUpdatePassword.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.userData.password = payload.newPassword;
        state.status = 'succeeded';
        state.messageUser = messageSuccessUpdatePlatform;
        state.isShowMessage = true;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchUpdatePassword.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetVisitedOrganizations.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetVisitedOrganizations.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allOrganizationsVisitedUser = [...payload.data];
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetVisitedOrganizations.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetTotalVisitedOrganizations.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetTotalVisitedOrganizations.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allOrganizationsVisitedUserTotal = payload.data;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetTotalVisitedOrganizations.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetVisitedPlatforms.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetVisitedPlatforms.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allPlatformsVisitedUser = [...payload.data];
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetVisitedPlatforms.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetTotalVisitedPlatforms.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetTotalVisitedPlatforms.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allPlatformsVisitedUserTotal = payload.data;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetTotalVisitedPlatforms.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetVisitedServices.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetVisitedServices.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allServicesVisitedUser = [...payload.data];
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetVisitedServices.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetTotalVisitedServices.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetTotalVisitedServices.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allServicesVisitedUserTotal = payload.data;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetTotalVisitedServices.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });

    builder.addCase(fetchGetUserClosestOrder.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetUserClosestOrder.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.closestOrderUser = payload.data;
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetUserClosestOrder.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });
    builder.addCase(fetchGetAllUsersBlackList.pending, (state: UserDataState) => {
      state.isShowLoader = true;
      state.status = 'loading';
    });
    builder.addCase(fetchGetAllUsersBlackList.fulfilled, (state: UserDataState, {payload}) => {
      if (payload?.status === 200) {
        state.status = 'succeeded';
        state.allBlackUsers = payload.data;
        state.allBlackTotalCount = payload.totalCount;
      }
      if (payload?.status === 204) {
        state.status = 'succeeded';
        state.allBlackUsers = [];
      }
      state.isShowLoader = false;
    });
    builder.addCase(fetchGetAllUsersBlackList.rejected, (state: UserDataState) => {
      state.messageUser = messageErrorServer;
      state.isShowLoader = false;
      state.status = 'failed';
      state.isShowMessage = true;
    });
    builder.addCase(fetchGetAvatars.fulfilled, (state: UserDataState, {payload}) => {
      state.avatars = [...payload?.avatars];
    });
    builder.addCase(fetchGetOrderAttach.fulfilled, (state: UserDataState, {payload}) => {
      state.orderAttach = payload?.data;
    });
	builder.addCase(fetchGetHistoryOrdersByServiceUser.fulfilled, (state: UserDataState, {payload}) => {
		state.historyByServiceOrders = payload?.data;
	});
  },
});

export const {
  deleteOrderAttach,
  resetOrderAttach,
  setOrder,
  setOpenForUrl,
  resetBlackList,
  setIsUserCheckedService,
  setIsNetwork,
  setIsLoginPage,
  setBlackList,
  setStepRegistration1,
  setStepRegistration2,
  resetDataUser,
  setLoginCheckedUserForAddRole,
  setMessageUser,
  setIsAuthUser,
  setShowLoader,
  setIsShowRegOrAuth,
  setNotErrorAuth,
  setUserData,
  setIsShowMessage,
  setIsShowAuth,
  setResetIsAuth,
  setResetMessageUser,
  setUserCity,
  setHistoryOrders,
  setHistoryOrdersTotal,
  setUserComments,
  clearCommentaries,
  setSelectedAdminFullRole,
} = userDataSlice.actions;
