import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;

	.slider {
		background-color: #ccc;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: .4s;
	}

	.slider:before {
		background-color: var(--white-color);
		bottom: 4px;
		content: "";
		height: 26px;
		left: 4px;
		position: absolute;
		transition: .4s;
		width: 26px;
	}

	input:checked + .slider {
		background-color: var(--background-Brand-B);
	}

	input:checked + .slider:before {
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;
export const Switch = styled.label`
	display: inline-block;
	height: 34px;
	position: relative;
	width: 60px;

	input {
		display: none;
	}
`;
export const Label = styled.span`
	color: var(--text-Weak);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
