import {createAsyncThunk} from '@reduxjs/toolkit';

import {UserCommentAPI} from '../../../api/users-comments-api';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetSingleOrder = createAsyncThunk(
	'ordersSlice/fetchGetSingleOrder',
	async (id: string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await UserCommentAPI.getAllSingleOrder(id);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
