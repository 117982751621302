import styled from '@emotion/styled';

export const LogoImage = styled.img`
	width: 240px;
	min-width: 240px;
	height: 50px;
	min-height: 50px;

	@media screen and (max-width: 800px) {
		width: 168px;
		min-width: 168px;
		height: 40px;
		min-height: 40px;
	}
`;
